import { IconProp, SizeProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import add from "assets/icons/white-icon/add.svg";


// TYPES
interface ButtonProps {
    type?: "submit" | "button" | "reset" | undefined;
    className?: string;
    text?: string;
    awesomeIcon?: IconProp;
    icon?: string;
    tooltip?: string;
    styles?: string;
    disabled?: boolean;
    onClick?: any;
    isLoading?: boolean;
    loaderClass?: string;
    form?: string;
    color?: string;
    size?: SizeProp;
}

/* ============================== BUTTON ============================== */

const Button = (props: ButtonProps) => {
    const {
        type = "button",
        className,
        text,
        awesomeIcon,
        styles = "primary",
        disabled,
        onClick,
        isLoading = false,
        loaderClass,
        form,
        color = "white",
        size = "xl",
        icon
    } = props;

    const icons:any = {
        'add-white': add
    }

    const style: any = {
        primary: "bg-primary text-white",
        "primary-green": "bg-primary-green text-white",
        "primary-outline": "bg-transparent border-2 border-primary text-heading",
        "primary-green-outline":
            "bg-transparent border-2 border-primary-green text-heading",
        "primary-outline-text":
            "bg-transparent border-2 border-primary text-primary",
        secondary: "bg-secondary text-white",
        "secondary-outline":
            "bg-transparent border-2 border-secondary text-heading",
        error: "bg-error text-white",
        "error-outline": "bg-transparent border-2 border-error text-heading",
        dark: "bg-dark-700 text-txt-primary",
        "dark-outline": "bg-transparent border-2 border-dark-700 text-heading",
        "dark-transparent":
            "bg-transparent border-2 border-dark-700 text-txt-primary",
    };

    return (
        <>
            <div className="hover:bg-opacity-60">
                <button
                    form={form}
                    className={`flex gap-2 items-center font-semibold text-xs px-3 py-1.5 rounded-md justify-center whitespace-nowrap ${style[styles]} ${className}`}
                    type={type}
                    disabled={disabled}
                    onClick={onClick}
                >
                    {awesomeIcon && (
                        <FontAwesomeIcon
                            className="cursor-pointer"
                            color={color}
                            icon={awesomeIcon}
                            size={size}
                        />
                    )}
                    {icon && (
                        <img src={icons[icon]} alt={icon}/>
                    )}
                    {isLoading && (
                        <div
                            className={`${loaderClass} mr-2 h-4 w-4 border-t-transparent border-spacing-1 animate-spin rounded-full border-white border-2`}
                        ></div>
                    )}
                    {text}
                </button>
            </div>
        </>
    );
};

export default Button;
