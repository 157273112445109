import { Flex, Space } from "antd";
import { useSearchParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import BrandLogo from "../../components/BrandLogo/BrandLogo";
import TryAgainLoginWrapper from "./tryAgainLogin.style";
import Auth0Icon from "../../components/Icons/Auth0Icon";
import useScreenDetector from "../../hooks/useScreenDetector";
import { Button } from "components/ui-component/forms";

const TryAgainLogin = () => {
  const [searchParams] = useSearchParams();
  const { logout } = useAuth0();
  const { isSmallMobile } = useScreenDetector();

  const handleOnLogin = () => {
    logout();
    localStorage.removeItem("accessToken");
    localStorage.clear();
  };

  return (
    <TryAgainLoginWrapper isSmallMobile={isSmallMobile}>
      <Flex
        align="center"
        vertical
        gap={20}
        justify="center"
        className="login-card-wrapper"
      >
        <div className="login-card">
          <Flex
            gap={20}
            align="center"
            vertical
            style={{ marginBottom: "40px" }}
          >
            <BrandLogo style={{ width: "100%" }} />
            <h2 className="title">OMS Sandbox</h2>
            <p className="error">
              {searchParams.get("error_description") ||
                "Something went wrong!!"}
            </p>
          </Flex>

          <Space direction="vertical" size={20} style={{ width: "100%" }}>
            <div
              className="link-btn hover:underline cursor-pointer"
              onClick={handleOnLogin}
            >
              <b>Can't login to your account?</b>
            </div>
            <Button
              className="try-again-btn"
              text="Try a different Account"
              type="button"
              onClick={handleOnLogin}
            />
          </Space>
        </div>
        {!isSmallMobile ? <Auth0Icon /> : null}
      </Flex>
    </TryAgainLoginWrapper>
  );
};

export default TryAgainLogin;
