// PROJECT IMPORTS
import ConfirmationModal from "./ConfirmationModal";
import Cookies from "js-cookie";

// TYPES
interface WelcomeModalProps {
    visible: boolean;
    isLoading?: boolean;
    onCancelClick?: () => void;
    onDoneClick?: () => void;
    welcomeMessage?: string;
}

/* ============================== WELCOME MODAL ============================== */

const WelcomeModal = (props: WelcomeModalProps) => {
    const {
        visible = false,
        isLoading = false,
        onCancelClick,
        welcomeMessage = process.env.REACT_APP_WELCOME_MESSAGE || "",
    } = props;


    // const [title, content] = welcomeMessage.split(". ");
    // const WELCOME_URL = process.env.REACT_APP_WELCOME_URL;
    const [title, content] = welcomeMessage.split('. ').filter(Boolean);

    // const [showAgain, setShowAgain] = useState(true);

    const handleDoneClick = () => {
        // console.log("COOOMING HERE");
        // const showWelcomeModal = localStorage.getItem("showWelcomeModal");
        // console.log(showWelcomeModal);
        // console.log(showAgain);
        // if (showWelcomeModal == null) {
        //     localStorage.setItem("showWelcomeModal", "false");
        // }
        Cookies.set("showWelcomeModal", "false", { expires: 365 });
        onCancelClick?.();
    };

    // const [contentstart, url] = content.split(".");


    return (
        <>
             <ConfirmationModal
                isLoading={isLoading}
                visible={visible}
                onCancelClick={handleDoneClick}
                onDoneClick={onCancelClick}
                doneText="Close"
                cancelText="Don’t show again"
                title={title}
                infoIcon={false}
                className="!max-w-[430px] !top-[calc(50%-150px)]"
            >
                <div>
                   
              
                        <div
                        className="pt-4 pb-3 text-sm text-black text-secondary font-normal infoMsg"
                        dangerouslySetInnerHTML={{ __html: welcomeMessage }}
                    ></div>
                </div>
            </ConfirmationModal>
        </>
    );
};

export default WelcomeModal;
