import { useEffect, useState } from "react";
import Modal from "./Modal";
import Table from "../Table/Table";
import { format } from "date-fns";

interface FaxLogModalProps {
    data: any[];
    visible: boolean;
    onCancelClick: any;
}

const FaxLogModal = (props: FaxLogModalProps) => {
    const { data, visible = false, onCancelClick } = props;

    const [tableData, setTableData] = useState<any>();
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        setLoading(true);
        const faxLogs: any[] = data?.map((entry: string) => {
	     const matchResult = entry.match(
        		/\[(\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2} UTC)\]\[([^\]]+@[\w.]+)\]\[([^\]]+)\]\[([^\]]+)]/
	      );

	      if (matchResult) {
	        const [timestamp, email, ipAddress, action] = matchResult.slice(1);
       		 return { timestamp, email, ipAddress, action };
	      }

	      return null;    
        });
        setTableData(faxLogs);
        setLoading(false);
    }, [data]);

    const col = [
        {
            name: "Date",
            cell: (row: any) => {
                return (
                    <div className="flex gap-2 items-center">
                        {format(new Date(row.timestamp), "M-d-yyyy, hh:mm a")}
                    </div>
                );
            },
            selector: (row: any) => row.timestamp,
            sortable: false,
        },
        {
            name: "Email",
            selector: (row: any) => row.email,
            sortable: false,
        },
        {
            name: "Action",
            selector: (row: any) => row.action,
            sortable: false,
        },
        {
            name: "Ip Address",
            selector: (row: any) => row.ipAddress,
            sortable: false,
        },
    ];

    return (
        <Modal visible={visible} className="!p-0" onCancelClick={onCancelClick}>
            <Table
                columns={col}
                pagination={false}
                data={tableData}
                isLoading={loading}
            />
        </Modal>
    );
};

export default FaxLogModal;
