// PROJECT IMPORTS
import ConfirmationModal from "./ConfirmationModal";

// TYPES
interface SignOutModalProps {
  visible: boolean;
  isLoading?: boolean;
  onCancelClick?: any;
  onDoneClick?: any;
}

/* ============================== DELETE MODEL ============================== */

const SignOutModal = (props: SignOutModalProps) => {
  const {
    visible = false,
    isLoading = false,
    onCancelClick,
    onDoneClick,
  } = props;

  return (
    <>
      <ConfirmationModal
        isLoading={isLoading}
        visible={visible}
        title="Are you sure you want to sign out?"
        onCancelClick={onCancelClick}
        onDoneClick={onDoneClick}
        className="!max-w-[430px]"
      />
    </>
  );
};

export default SignOutModal;
