import React from "react";
import { useNavigate } from "react-router-dom";

import {
  getUserInfo,
  useInputListDetails,
  useNumberDetails,
} from "redux/slice/faxSlice";
import { useAppDispatch } from "redux/hooks";
import {
  setCustomeRangeIn,
  setDeleteHideIn,
  setErrorPageData,
  setInboxType,
  setIsCustomeRangeIn,
  setIsErrorPage,
  setLoading,
  setUserInfo,
  useCustomeRangeIn,
  useHideDeleteIn,
  useInboxType,
  useIsCustomeRangeIn,
} from "redux/slice/commanSlice";
import { faRefresh, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, CheckBox, Input } from "components/ui-component/forms";
import Pagination from "components/ui-component/Table/Pagination";
import Table from "components/ui-component/Table/Table";
import {
  DownloadFile,
  // getFilenameFromContentDisposition,
} from "components/Helper/DownloadFile";
import Loader from "components/ui-component/loader/Loader";

import download from "assets/icons/download.svg";
import edit from "assets/icons/gray-icon/edit.svg";
import trash from "assets/icons/trash.svg";
import mail from "assets/icons/mail.svg";
import readmail from "assets/icons/read-mail.svg";
import lock from "assets/icons/lock.svg";
import calendar from "assets/icons/calendar.svg";
import nodata from "assets/images/no-data.svg";
// import searchIcon from "assets/icons/search.svg";

import read_eye from "assets/icons/read_eye.svg";
import unread_eye from "assets/icons/unread_eye.svg";
import { format } from "date-fns";
import { message } from "antd";
import { useAuth0 } from "@auth0/auth0-react";

interface InboxProps {
  filter: any;
  setFilter: any;
  setDeleteId: any;
  setSentFaxData: any;
  setIsWarn: any;
  isLoading: boolean;
  setOpenDate: any;
  setFaxLogs: any;
}

const Inbox = (props: InboxProps) => {
  const {
    setDeleteId,
    setSentFaxData,
    setFilter,
    filter,
    setIsWarn,
    isLoading,
    setOpenDate,
    // setFaxLogs,
  } = props;

  const { user } = useAuth0();
  const navigation = useNavigate();
  const inputFaxList = useInputListDetails();
  const dispatch = useAppDispatch();
  const inboxType = useInboxType();
  const numberDetails = useNumberDetails();
  const hide_deletedIn = useHideDeleteIn();
  const customRangeIn = useCustomeRangeIn();
  const isCustomRangeIn = useIsCustomeRangeIn();

  const [open, setOpen] = React.useState<boolean>(false);
  const [search, setSearch] = React.useState<boolean>(
    filter.search_query === "" ? false : true
  );

  const List = [
    "All",
    "Today",
    "Yesterday",
    "Last 7 Days",
    "Last 30 Days",
    "Custom Range",
  ];

  const onDownloadFax = async (data: any, path: string) => {
    onGetUserInfo();
    const accessToken = localStorage.getItem("accessToken");
    try {
      const response = await fetch(path, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (response.ok) {
        const responseArrayBuffer = await response.arrayBuffer();
        // const filename = getFilenameFromContentDisposition(response.headers);
        // const filename = `to-${data?.to_number}-from-${data?.from_number}-${format(new Date(),"yyyy-MM-dd_hh:mm:ss")}.pdf"`
        DownloadFile(responseArrayBuffer, data?.friendly_name);
      } else {
        message.error("Sorry, we couldn't download that document");
      }
    } catch (error) {
      console.error("Error:", error);
      message.error("Sorry, we couldn't download that document");
    }
  };

  const onGetUserInfo = async () => {
    try {
      const username = user?.email;
      if (username !== undefined) {
        const res: any = await dispatch(getUserInfo(username)).unwrap();
        // linkAccount();
        if (res.error === "invalid user") {
          dispatch(setErrorPageData(res));
          dispatch(setIsErrorPage(true));
          navigation("/invalid-user");
          dispatch(setLoading(false));
        }
        dispatch(setUserInfo(false));
        dispatch(setLoading(false));
      } else {
        dispatch(setUserInfo(false));
        dispatch(setLoading(false));
      }
    } catch (error: any) {
      dispatch(setIsErrorPage(true));
      navigation("/invalid-user");
      dispatch(setLoading(false));
      console.log("Get USer Info Err: --->", error?.message);
    }
  };

  React.useEffect(() => {
    onSetSearchDate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inboxType]);

  const onSetSearchDate = () => {
    switch (inboxType) {
      case "All":
        setFilter({
          ...filter,
          page: "1",
          per_page: "10",
          start_date: undefined,
          end_date: undefined,
        });
        break;
      case "Today":
        setFilter({
          ...filter,
          page: "1",
          per_page: "10",
          start_date: format(
            new Date().setHours(0, 0, 1),
            "yyyy-MM-dd HH:mm:ss"
          ),
          end_date: format(
            new Date().setHours(23, 59, 59),
            "yyyy-MM-dd HH:mm:ss"
          ),
        });
        break;
      case "Yesterday":
        setFilter({
          ...filter,
          page: "1",
          per_page: "10",
          start_date: format(
            new Date(Date.now() - 1 * 24 * 60 * 60 * 1000).setHours(0, 0, 1),
            "yyyy-MM-dd HH:mm:ss"
          ),
          end_date: format(
            new Date(Date.now() - 1 * 24 * 60 * 60 * 1000).setHours(23, 59, 59),
            "yyyy-MM-dd HH:mm:ss"
          ),
        });
        break;
      case "Last 7 Days":
        setFilter({
          ...filter,
          page: "1",
          per_page: "10",
          start_date: format(
            new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).setHours(0, 0, 1),
            "yyyy-MM-dd HH:mm:ss"
          ),
          end_date: format(
            new Date().setHours(23, 59, 59),
            "yyyy-MM-dd HH:mm:ss"
          ),
        });
        break;
      case "Last 30 Days":
        setFilter({
          ...filter,
          page: "1",
          per_page: "10",
          start_date: format(
            new Date(Date.now() - 30 * 24 * 60 * 60 * 1000).setHours(0, 0, 1),
            "yyyy-MM-dd HH:mm:ss"
          ),
          end_date: format(
            new Date().setHours(23, 59, 59),
            "yyyy-MM-dd HH:mm:ss"
          ),
        });
        break;

      case "Custom Range":
        if (isCustomRangeIn) {
          setFilter({
            ...filter,
            page: "1",
            per_page: "10",
            start_date: format(
              new Date(customRangeIn?.start_date).setHours(0, 0, 1),
              "yyyy-MM-dd HH:mm:ss"
            ),
            end_date: format(
              new Date(customRangeIn?.end_date).setHours(23, 59, 59),
              "yyyy-MM-dd HH:mm:ss"
            ),
          });
        }
        break;

      default:
        break;
    }
  };

  const columns = [
    {
      name: "From Number",
      selector: (row: any) => row.from_number,
      cell: (row: any) => {
        return (
          <div className="flex gap-3 items-center">
            <img
              className="cursor-pointer"
              src={row?.viewed === "yes" ? read_eye : unread_eye}
              alt="unread"
              title={row?.viewed === "yes" ? "Read" : "Unread"}
            />
            <label>{row.from_number}</label>
            <img
              className="cursor-pointer"
              src={lock}
              alt="lock"
              title="To ensure the utmost security and confidentiality, all faxes are individually encrypted using a Key Management Service"
            />
          </div>
        );
      },
      sortable: true,
      width: "18%",
    },
    {
      name: "To Number",
      selector: (row: any) => row.to_number,
      sortable: true,
      width: "12%",
    },
    {
      name: "Date",
      selector: (row: any) => row.created_on,
      sortable: true,
      width: "18%",
    },
    {
      name: "Pages",
      selector: (row: any) => row.pages,
      sortable: true,
      // width: "180px",
      width: "10%",
    },
    {
      name: "Notes",
      selector: (row: any) => row.notes,
      sortable: true,
      width: "22%",
    },
    // {
    //   name: "Download",
    //   selector: (row: any) => row.notes,
    //   cell: (row: any, index: number) => {
    //     return (
    //       <div className="flex gap-3 items-center">

    //         {row?.download_link ? (
    //           <div
    //             className="cursor-pointer"
    //             onClick={() => onDownloadFax(row, row?.download_link)}
    //           >
    //             <img src={download} alt="download" width="17px" height="17px"/>
    //           </div>
    //         ) : null}

    //       </div>
    //     );
    //   },
    // },
    {
      name: "",
      right: "true",
      width: "20%",
      cell: (row: any, index: number) => {
        return (
          <div className="flex gap-3 items-center">
            {/* {row?.fax_log && row?.fax_log?.length ? (
              <div
                className="cursor-pointer"
                onClick={() => {
                  setFaxLogs(row?.fax_log);
                }}
              >
                <img src={searchIcon} alt="search" height={15} width={15} />
              </div>
            ) : null} */}
            {/* {row?.download_link ? (
              <div
                className="cursor-pointer"
                onClick={() => onDownloadFax(row, row?.download_link)}
              >
                <img src={download} alt="download" width="17px" height="17px"/>
              </div>
            ) : null} */}
            {row?.download_link ? (
                    <button
                      className="bg-primary rounded-3xl text-white px-2 py-1 text-xs inline-flex items-center justify-center"
                      onClick={() => onDownloadFax(row, row?.download_link)}
                      title="Download Fax"
                      style={{ fontSize: 'calc(0.6rem + 0.2vw)' }}
                    >
                      <img src={download} alt="download" width="10px" height="10px" className="w-4 h-4 mr-1" style={{ filter: 'invert(1)' }}/>
                      <span style={{ whiteSpace: 'nowrap' }}> Download </span>
                    </button>
                  ) : null}
            <div className="cursor-pointer" onClick={() => setSentFaxData(row)} title="Edit">
              <img src={edit} alt="edit" width="17px" height="17px"/>
            </div>
            <div
              className="cursor-pointer"
              onClick={() => setDeleteId(row?.uuid)}
              title="Delete"
            >
              <img src={trash} alt="trash" width="16px" height="16px" />
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <div onClick={() => setOpen(false)}>
      <div className="flex justify-between pb-2 px-1 smd:gap-y-3 smd:px-2">
        <div className="flex gap-4 items-center smd:flex-wrap">
          <div className="flex items-center gap-2">
            {inputFaxList?.total_faxes > 0 ? (
              <label className="text-sm font-bold text-heading">{`Inbox (${inputFaxList?.total_faxes || 0
                })`}</label>
            ) : (
              <label className="text-sm font-bold text-heading">Inbox</label>
            )}
            <FontAwesomeIcon
              className="cursor-pointer"
              color="#38a6de"
              icon={faRefresh}
              onClick={() => setFilter({ ...filter })}
            />
          </div>
          <div
            className="relative smd:hidden"
            onClick={(e: any) => e.stopPropagation()}
          >
            <div
              className="flex gap-2 items-center bg-[#ECEEF5] rounded-2xl px-2 py-1"
              onClick={() => setOpen(!open)}
            >
              <img src={calendar} alt="calendar" />
              <label className="text-xs font-semibold text-black">
                {inboxType === "Custom Range"
                  ? customRangeIn?.start_date + " - " + customRangeIn?.end_date
                  : inboxType}
              </label>
            </div>
            <div
              className={`absolute top-8 left-0 bg-white rounded-md drop-shadow-md py-1 w-[120px] z-[2] flex flex-col ${open ? "block" : "hidden"
                }`}
            >
              {List?.map((val: any, index: number) => {
                return (
                  <div
                    key={index}
                    className={`flex items-center gap-3 py-1 px-3 cursor-pointer ${val === "Custom Range"
                        ? "!text-primary"
                        : "border-b border-dark-800 text-black"
                      }`}
                    onClick={() => {
                      setOpen(false);
                      val === "Custom Range" &&
                        dispatch(
                          setCustomeRangeIn({
                            start_date: format(
                              new Date().setHours(0, 0, 1),
                              "yyyy-MM-dd HH:mm:ss"
                            ),
                            end_date: format(
                              new Date().setHours(23, 59, 59),
                              "yyyy-MM-dd HH:mm:ss"
                            ),
                          })
                        );
                      dispatch(setInboxType(val));
                      val === "Custom Range" && setOpenDate(true);
                      val !== "Custom Range" &&
                        dispatch(setIsCustomeRangeIn(false));
                      onSetSearchDate();
                    }}
                  >
                    <span className="text-xs font-semibold">{val}</span>
                  </div>
                );
              })}
            </div>
          </div>
          <div className=" smd:hidden">
            <CheckBox
              label="Show Deleted Faxes"
              value={hide_deletedIn}
              checked={hide_deletedIn === "no" ? true : false}
              onChange={() => {
                if (hide_deletedIn === "yes") {
                  setFilter({
                    ...filter,
                    page: "1",
                    per_page: "10",
                    hide_deleted: "no",
                  });
                } else {
                  setFilter({
                    ...filter,
                    page: "1",
                    per_page: "10",
                    hide_deleted: "yes",
                  });
                }
                dispatch(
                  setDeleteHideIn(hide_deletedIn === "no" ? "yes" : "no")
                );
              }}
            />
          </div>
        </div>
        <div className="flex items-center gap-2 smd:justify-end">
          <div className="border-dark-700 border px-1.5 py-0.5 rounded-md cursor-pointer flex items-center">
            <div
              className={`${search ? "border-r border-dark-700 pr-1.5" : ""}`}
            >
              <FontAwesomeIcon
                className="cursor-pointer"
                color="#8083A3"
                icon={faSearch}
                onClick={() => {
                  if (search) {
                    setFilter({
                      ...filter,
                      search_query: "",
                    });
                  }
                  setSearch(!search);
                }}
              />
            </div>
            <Input
              name="search"
              isShowLabel={false}
              value={filter.search_query}
              placeholder="Search by name, phone and extension number"
              className={`${!search && "hidden"
                } transition ease-in-out delay-1000 resize-none rounded-md w-[275px] !py-[5px] focus:outline-none !border-none`}
              onChange={(e: any) => {
                setFilter({
                  ...filter,
                  search_query: e.target.value,
                });
              }}
            />
          </div>
          <Button
            className="smd:hidden"
            text="Compose a fax"
            icon="add-white"
            onClick={() => {
              //if (
              //  numberDetails?.total_pages >= numberDetails?.license_page_limit
              //) {
              //  setIsWarn(true);
              //} else {
                navigation("/fax/compose-fax");
              //}
            }}
          />
        </div>
      </div>
      <div className="hidden smd:block h-[calc(100vh-152px)] bg-[#F7FAFD] overflow-y-auto pb-[55px]">
        {isLoading ? (
          <Loader />
        ) : inputFaxList?.faxes?.length ? (
          <div className="">
            {inputFaxList?.faxes.map((val: any, index: number) => {
              return (
                <div className="p-2" key={index}>
                  <div className="bg-white p-4 rounded-lg drop-shadow-sm">
                    <div className="grid grid-cols-5 items-center">
                      <div className="flex gap-3 items-center col-span-3">
                        <label className="text-sm">
                          To: {val.to_number || ""}
                        </label>
                      </div>
                      <div className="col-span-2 flex justify-end gap-4">
                        <img
                          width="15px"
                          height="15px"
                          className="cursor-pointer"
                          src={val?.viewed === "yes" ? readmail : mail}
                          alt="unread"
                          title={val?.viewed === "yes" ? "Read" : "Unread"}
                        />
                        <img
                          width="15px"
                          height="15px"
                          className="cursor-pointer"
                          src={lock}
                          alt="lock"
                          title="To ensure the utmost security and confidentiality, all faxes are individually encrypted using a Key Management Service"
                        />
                      </div>
                    </div>
                    <div className="grid grid-cols-2 items-center pt-2">
                      <label className="text-sm">
                        From: {val.from_number || ""}
                      </label>
                      <label className="text-sm text-right">
                        {val.created_on || ""}
                      </label>
                    </div>
                    <div className="pt-2 font-semibold">
                      <label className="text-sm">{val.notes || ""}</label>
                    </div>
                    <div className="pt-2 flex items-center justify-between">
                      {/* {val?.fax_log && val?.fax_log?.length ? (
                        <div
                          className="flex items-center gap-2 cursor-pointer"
                          onClick={() => {
                            setFaxLogs(val?.fax_log);
                          }}
                        >
                          <img
                            src={searchIcon}
                            alt="search"
                            height={15}
                            width={15}
                          />
                          <span className="text-[13px] pt-0.5 text-black">
                                                            Fax Log
                                                        </span>
                        </div>
                      ) : null} */}
                      {val?.download_link ? (
                        <div
                          className="flex items-center gap-2 cursor-pointer"
                          onClick={() => {
                            onDownloadFax(val, val?.download_link);
                          }}
                        >
                          <img src={download} alt="download" />
                          <span className="text-[13px] pt-0.5 text-black">
                            Download
                          </span>
                        </div>
                      ) : null}
                      <div
                        className="flex items-center gap-2 cursor-pointer"
                        onClick={() => {
                          setSentFaxData(val);
                        }}
                      >
                        <img src={edit} alt="edit" />
                        <span className="text-[13px] pt-0.5 text-black">
                          Edit
                        </span>
                      </div>
                      <div
                        className="flex items-center gap-2 cursor-pointer"
                        onClick={() => {
                          setDeleteId(val?.uuid);
                        }}
                      >
                        <img
                          src={trash}
                          alt="trash"
                          width="14px"
                          height="14px"
                        />
                        <span className="text-[13px] pt-0.5 text-error">
                          Delete
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
            <div className="absolute bottom-0 w-full">
              <Pagination
                count={inputFaxList?.faxes?.length || 0}
                totalCount={inputFaxList?.total_faxes || 0}
                page={filter?.page}
                limit={filter?.per_page}
                onPageClick={(page: number) => {
                  setFilter({
                    ...filter,
                    page,
                  });
                }}
                onLimitChange={(limit: number) => {
                  setFilter({
                    ...filter,
                    per_page: limit,
                    page: 1,
                  });
                }}
                firstPageClick={() => {
                  setFilter({
                    ...filter,
                    page: 1,
                  });
                }}
                lastPageClick={() => {
                  setFilter({
                    ...filter,
                    page: Math.ceil(
                      inputFaxList?.total_faxes / filter.per_page
                    ),
                  });
                }}
              />
            </div>
          </div>
        ) : (
          <div className="flex flex-col items-center justify-center gap-2 h-full">
            <img src={nodata} alt="no data" height={100} width={100} />
            <label className="text-base font-bold text-black">
              No Faxes Found
            </label>
          </div>
        )}
      </div>
      <div className="smd:hidden">
        <Table
          isLoading={isLoading}
          columns={columns}
          data={inputFaxList?.faxes?.length ? inputFaxList?.faxes : []}
          totalCount={inputFaxList?.total_faxes || 0}
          page={filter?.page}
          limit={filter?.per_page}
          onPageClick={(page: number) => {
            setFilter({
              ...filter,
              page,
            });
          }}
          onLimitChange={(limit: number) => {
            setFilter({
              ...filter,
              per_page: limit,
              page: 1,
            });
          }}
          conditionalRowStyles={[{
            when: (row: any) => row?.viewed === 'no',
            style: (row: any) => ({ backgroundColor: row?.viewed !== 'no' ? 'inherit' : '#C1ECFF',
            color: row?.viewed !== 'no' ? '': 'black' }),
          }]}
        />
      </div>
    </div>
  );
};

export default Inbox;
