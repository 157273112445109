// PROJECT IMPORTS
import {
  DELETE_FAXES,
  DOWNLOAD_FAXES,
  FAXES,
  GET_INFO,
  GET_NUMBER,
  RESEND_FAX,
  SEND_FAX,
  UPDATE_FAXES,
  USER_MIGRATION,
  CONTACTS,
  FAX_STATUS
} from "API/constAPI";
import { apiInstance } from "redux/axiosApi";

/* ============================== FAX SERVICES ============================== */

export const faxListGet = (params: any) => {
  return apiInstance.get(FAXES, { params });
};

export const faxListDelete = (payload: any) => {
  return apiInstance.delete(DELETE_FAXES, payload);
};

export const faxDownload = (id: string) => {
  return apiInstance.get(DOWNLOAD_FAXES + `/${id}`, {
    responseType: "arraybuffer",
  });
};

export const faxUpdate = (id: string, payload: any) => {
  return apiInstance.post(UPDATE_FAXES + `/${id}`, payload);
};

export const faxSend = (payload: any) => {
  return apiInstance.post(SEND_FAX, payload);
};

export const faxResend = (id: string) => {
  return apiInstance.get(RESEND_FAX + `/${id}`);
};

export const userInfoGet = (username: string) => {
  return apiInstance.get(GET_INFO + `/${username}`);
};

export const userNumberGet = (number: string) => {
  return apiInstance.get(GET_NUMBER + `/${number}`);
};

export const migrationUser = (payload: any) => {
  return apiInstance.post(USER_MIGRATION, payload);
};

export const contactListGet = (payload: any) => {
  return apiInstance.post(CONTACTS, payload);
};

export const contactCreate = (payload: any) => {
  return apiInstance.put(CONTACTS, payload);
};

export const contactEdit = (payload: any) => {
  return apiInstance.patch(`${CONTACTS}/${payload.username}/${payload.id}`, payload);
  // return apiInstance.patch(CONTACTS+ `/${payload.username}`+ `/${payload.id}` , payload);
};

export const faxSendingstatus = (uuid: string) => {
  return apiInstance.get(FAX_STATUS + `/${uuid}`);
};