// ASSETS
import logo from "assets/images/SecureFaxLogo.svg";

/* ============================== LOGO SECTION ============================== */

const LogoSection = () => {
  return (
    <>
      <div className="flex items-center justify-between h-[55px] border-dark-700 p-3">
        <div className="cursor-pointer">
          <img src={logo} width={135} alt="logo" />
        </div>
      </div>
    </>
  );
};

export default LogoSection;
