// PROJECT IMPORTS
import ConfirmationModal from "./ConfirmationModal";

// TYPES
interface DeleteProps {
  visible: boolean;
  isLoading?: boolean;
  onCancelClick?: any;
  onDoneClick?: any;
}

/* ============================== DELETE MODEL ============================== */

const WarnModal = (props: DeleteProps) => {
  const { visible = false, isLoading = false, onDoneClick } = props;

  return (
    <>
      <ConfirmationModal
        isLoading={isLoading}
        visible={visible}
        className="!max-w-[450px]"
        title="You have reached the limit of used pages for this month"
        onDoneClick={onDoneClick}
        cancel={false}
        doneText="Ok"
      />
    </>
  );
};

export default WarnModal;
