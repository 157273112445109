import { faGear, faSignOut } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAppDispatch } from "redux/hooks";
import { Select } from "../forms";
import { useNumberDetails, useUserDetails } from "redux/slice/faxSlice";
import { setNumber, useNumber } from "redux/slice/commanSlice";

// TYPES
interface LogoutPopupProps {
    visible: boolean;
    className?: string;
    onLogoutClick?: any;
    onProfileClick?: any;
    onCancelClick?: any;
    onMigrationClick?: any;
}

/* ============================== LOGOUT POPUP ============================== */

const LogoutPopup = (props: LogoutPopupProps) => {
    const { visible, className, onLogoutClick, onCancelClick, onMigrationClick } =
        props;

    const dispatch = useAppDispatch();
    const userDetails = useUserDetails();
    const numberDetails = useNumberDetails();
    const number = useNumber();

    const numberOptions = () => {
        let data = userDetails?.assigned_number || [];
        let options = data?.map((x: string) => ({ value: x, label: x }));
        return options;
    };

    return (
        <>
            <div
                className={`fixed top-0 left-0 right-0 z-50 px-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[100%] max-h-full bg-transparent ${!visible && "hidden"
                    }`}
                onClick={onCancelClick}
            >
                <div className="w-full h-full relative flex justify-center">
                    <div
                        onClick={(e) => e.stopPropagation()}
                        className={`${className} w-[180px] bg-white rounded-lg p-3 drop-shadow-xl ${!visible && "hidden"
                            }`}
                    >
                        <div className="smd:block hidden">
                            <div className="pb-2">
                                <Select
                                    label="Select Number"
                                    name="number"
                                    options={numberOptions() || []}
                                    value={number}
                                    onChange={(e: any) => dispatch(setNumber(e.target.value))}
                                />
                            </div>
                            <hr />
                            <div className="py-2">
                                <span className="text-xs font-semibold">
                                    This Month's Usage
                                </span>
                                <p className="text-sm text-black font-black">
                                    {numberDetails?.total_pages || 0}&nbsp;/&nbsp;
                                    {numberDetails?.license_page_limit || 0}
                                    &nbsp;Pages
                                </p>
                            </div>
                        </div>
                        <hr className="smd:block hidden"/>
                        <div
                            className="flex cursor-pointer items-center hover:text-primary gap-4 pt-2 pb-3"
                            onClick={onMigrationClick}
                        >
                            <FontAwesomeIcon color="#474747" icon={faGear} size="sm" />
                            <span className="text-xs font-semibold">Migrate Account</span>
                        </div>
                        <hr />
                        <div
                            className="flex cursor-pointer items-center hover:text-primary gap-4 pt-3 pb-2"
                            onClick={onLogoutClick}
                        >
                            <FontAwesomeIcon color="#474747" icon={faSignOut} size="sm" />
                            <span className="text-xs font-semibold">Sign out</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default LogoutPopup;
