import { useEffect, useState } from "react";

import Inbox from "components/fax/Inbox";
// import Cover from "components/fax/Cover";
import SentFax from "components/fax/SentFax";
import Contacts from "components/fax/Contacts";

import {
  DateRangePickerModal,
  DeleteModal,
  EditSentFaxModal,
  FaxLogModal,
  WarnModal,
} from "components/ui-component/modals";
import { useAppDispatch } from "redux/hooks";
import {
  getFaxList,
  getContactList,
  getSentFaxList,
  getUserInfo,
  getUserNumber,
  useNumberDetails,
  useUserDetails,
} from "redux/slice/faxSlice";

import fax from "assets/icons/fax.svg";
import add from "assets/icons/add.svg";
import {
  setCustomeRange,
  setCustomeRangeIn,
  setErrorPageData,
  setInboxType,
  setIsCustomeRange,
  setIsCustomeRangeIn,
  setIsErrorPage,
  setLoading,
  setNumber,
  setSentType,
  setSidebar,
  setTab,
  setUserInfo,
  useHideDelete,
  useHideDeleteIn,
  useNumber,
  useSidebarOpen,
  useStatusfilter,
  useTab,
} from "redux/slice/commanSlice";
import { baseUrl } from "API/baseURL";
import { DELETE_FAXES } from "API/constAPI";
import { useLocation, useNavigate } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";
import { useAuth0 } from "@auth0/auth0-react";
import { Select } from "components/ui-component/forms";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { message } from "antd";

const Fax = () => {
  const { user } = useAuth0();
  const [deleteId, setDeleteId] = useState<any>(null);
  const [faxLogs, setFaxLogs] = useState<any>(null);
  const [sentFaxData, setSentFaxData] = useState<any>(null);
  const [isWarn, setIsWarn] = useState<boolean>(false);
  const [isLoading, seIsLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [openIn, setOpenIn] = useState<boolean>(false);

  const userDetails = useUserDetails();
  const status_filter = useStatusfilter();
  const hide_deleted = useHideDelete();
  const hide_deletedIn = useHideDeleteIn();
  const sidebarOpen = useSidebarOpen();
  const numberDetails = useNumberDetails();
  const number = useNumber();

  const [inFilter, setInFilter] = useState<any>({
    page: "1",
    per_page: "10",
    search_query: "",
    order_by: "desc",
    hide_deleted: hide_deletedIn,
    start_date: undefined,
    end_date: undefined,
  });
  const [filter, setFilter] = useState<any>({
    page: "1",
    per_page: "10",
    search_query: "",
    order_by: "desc",
    status_filter: status_filter,
    hide_deleted: hide_deleted,
    start_date: undefined,
    end_date: undefined,
  });
  const [contactfilter, setContactFilter] = useState<any>({
    page: "1",
    per_page: "10",
    search_query: "",
    order_by: "desc",
  });
  const dispatch = useAppDispatch();
  const navigation = useNavigate();
  const activeTab = useTab();
  const location = useLocation();

  useEffect(() => {
    dispatch(setInboxType("All"));
    dispatch(setSentType("All"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const PageRender = () => {
    switch (activeTab) {
      case "fax":
        return (
          <SentFax
            setDeleteId={setDeleteId}
            setSentFaxData={setSentFaxData}
            setFilter={setFilter}
            filter={filter}
            setIsWarn={setIsWarn}
            isLoading={isLoading}
            setOpenDate={setOpen}
            setFaxLogs={setFaxLogs}
          />
        );
      case "contacts":
          return (
            <Contacts
              setDeleteId={setDeleteId}
              setSentFaxData={setSentFaxData}
              setContactFilter={setContactFilter}
              contactfilter={contactfilter}
              setIsWarn={setIsWarn}
              isLoading={isLoading}
              setOpenDate={setOpen}
              setFaxLogs={setFaxLogs}
            />
          );
      // case "cover":
      //   return <Cover />;

      default:
        return (
          <Inbox
            setDeleteId={setDeleteId}
            setSentFaxData={setSentFaxData}
            setFilter={setInFilter}
            filter={inFilter}
            setIsWarn={setIsWarn}
            isLoading={isLoading}
            setOpenDate={setOpenIn}
            setFaxLogs={setFaxLogs}
          />
        );
    }
  };

  const onGetUserInfo = async () => {
    try {
      const username = user?.email;
      if (username !== undefined) {
        const res: any = await dispatch(getUserInfo(username)).unwrap();
        // linkAccount();
        if (res.error === "invalid user") {
          dispatch(setErrorPageData(res));
          dispatch(setIsErrorPage(true));
          navigation("/invalid-user");
          dispatch(setLoading(false));
        }
        dispatch(setUserInfo(false));
        dispatch(setLoading(false));
      } else {
        dispatch(setUserInfo(false));
        dispatch(setLoading(false));
      }
    } catch (error: any) {
      dispatch(setIsErrorPage(true));
      navigation("/invalid-user");
      dispatch(setLoading(false));
      console.log("Get USer Info Err: --->", error?.message);
    }
  };

  const onGetUserNumber = async (number: string) => {
    try {
      await dispatch(getUserNumber(number)).unwrap();
    } catch (error: any) {
      console.log("Get USer number Err: --->", error?.message);
    }
  };
  useEffect(() => {
    onGetUserNumber(number);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [number]);

  useEffect(() => {
    if (activeTab === "fax") onSentFaxListGet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, number]);

  useEffect(() => {
    if (activeTab === "inbox") onFaxListGet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inFilter, number]);

  useEffect(() => {
    if (activeTab === "contacts") onContactListGet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactfilter, number]);

  const onFaxListGet = useDebouncedCallback(async () => {
    onGetUserInfo();
    seIsLoading(true);
    try {
      const params = {
        ...inFilter,
        username: user?.email,
        phone_number: number,
        direction: "in",
      };
      await dispatch(getFaxList(params)).unwrap();
      seIsLoading(false);
    } catch (error: any) {
      seIsLoading(false);
      console.log("fax list get error -->", error?.message);
    }
  }, 500);

  const onContactListGet = useDebouncedCallback(async () => {
    console.log("CALLING FUNCTION");
    onGetUserInfo();
    seIsLoading(true);
    try {
      const params = {
        ...contactfilter
      };
      await dispatch(getContactList(params)).unwrap();
      seIsLoading(false);
    } catch (error: any) {
      seIsLoading(false);
      console.log("contact list get error -->", error?.message);
    }
  }, 500);

  const onSentFaxListGet = useDebouncedCallback(async () => {
    onGetUserInfo();
    seIsLoading(true);
    try {
      const params = {
        ...filter,
        username: user?.email,
        phone_number: number,
        direction: "out",
      };
      if (params.status_filter === "all") delete params["status_filter"];
      await dispatch(getSentFaxList(params)).unwrap();
      seIsLoading(false);
    } catch (error: any) {
      seIsLoading(false);
      console.log("fax list get error -->", error?.message);
    }
  }, 500);

  const onDeleteFax = async (id: string) => {
    onGetUserInfo();
    const data = [id];
    const accessToken = localStorage.getItem("accessToken");
    fetch(`${baseUrl}${DELETE_FAXES}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Request failed with status " + response.status);
        }
      })
      .then((data) => {
        console.log("Request successful:", data);
        setDeleteId("");
        message.success("Fax deleted successfully");

        if (activeTab === "inbox") onFaxListGet();
        if (activeTab === "fax") onSentFaxListGet();
        if (activeTab === "contacts") onContactListGet();
      })
      .catch((error) => {
        console.error("Error:", error);
        setDeleteId("");
        message.error("Fax can't be deleted");
      });
  };

  const numberOptions = () => {
    let data = userDetails?.assigned_number || [];
    let options = data?.map((x: string) => ({ value: x, label: x }));
    return options;
  };

  return (
    <>
      <div className="flex h-[calc(100vh-55px)]">
        <div
          className={`w-[15%] ${sidebarOpen
              ? "smd:block smd:h-[calc(100vh-55px)] smd:!w-[200px] smd:z-[1] smd:fixed"
              : "smd:hidden smd:transition-all smd:delay-1000 smd:-translate-x-[200px]"
            }`}
        >
          <div className="h-full w-full bg-white p-3 flex flex-col justify-between">
            <div>
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-2">
                  <img src={fax} alt="fax" />
                  <p className="text-base font-bold text-heading">Faxes</p>
                </div>
                <img
                  src={add}
                  alt="add"
                  className="cursor-pointer"
                  onClick={() => navigation("/fax/compose-fax")}
                  title="Compose a fax"
                />
              </div>
              <div className="flex gap-2 flex-col pl-6 pt-4">
                <span
                  className={`text-sm cursor-pointer hover:text-primary font-bold ${activeTab === "inbox" ? "text-primary" : "text-txt-primary"
                    }`}
                  onClick={() => {
                    dispatch(setTab("inbox"));
                    dispatch(setSidebar(false));
                  }}
                >
                  Inbox
                </span>
                <span
                  className={`text-sm cursor-pointer hover:text-primary font-bold ${activeTab === "fax" ? "text-primary" : "text-txt-primary"
                    }`}
                  onClick={() => {
                    dispatch(setTab("fax"));
                    dispatch(setSidebar(false));
                  }}
                >
                  Sent Faxes
                </span>
                <span
                  className={`text-sm cursor-pointer hover:text-primary font-bold ${activeTab === "contacts" ? "text-primary" : "text-txt-primary"
                    }`}
                  onClick={() => {
                    dispatch(setTab("contacts"));
                    dispatch(setSidebar(false));
                  }}
                >
                  Contacts
                </span>
                {/* <span
                className={`text-sm cursor-pointer hover:text-primary font-bold ${activeTab === "cover" ? "text-primary" : "text-txt-primary"
                  }`}
                onClick={() => {
                  dispatch(setTab("cover"));
                }}
              >
                Cover Page
              </span> */}
              </div>
            </div>
            <div className="py-2">
              <div className="pb-2">
                <Select
                  label="Select Number"
                  name="number"
                  options={numberOptions() || []}
                  value={number}
                  onChange={(e: any) => dispatch(setNumber(e.target.value))}
                />
              </div>
              <div className="border-[#D5D6DC] border rounded-2xl bg-white p-3">
                <span className="text-sm text-black font-semibold">
                  This Month's Usage
                </span>
                <p className="text-base text-black font-black">
                  {numberDetails?.total_pages || 0}&nbsp;/&nbsp;
                  {numberDetails?.license_page_limit || 0}
                  &nbsp;Pages
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="w-[85%] h-[100%] p-4 smd:w-[100%] smd:p-0">
          <div className="bg-white h-[100%] rounded-md drop-shadow-md p-4 smd:py-2 smd:px-0 smd:h-[calc(100vh-55px)] smd:rounded-none smd:relative">
            <div
              className="absolute z-[99] hidden w-12 h-12 bg-primary smd:flex justify-center items-center bottom-16 right-5 rounded-full cursor-pointer drop-shadow-lg"
              onClick={() => navigation("/fax/compose-fax")}
            >
              <FontAwesomeIcon
                icon={faPlus}
                color="white"
                size="xl"
                title="Compose a fax"
              />
            </div>
            <div className="grid-cols-2 items-center mb-4 smd:grid hidden">
              <div
                className={`flex justify-center ${activeTab === "inbox"
                    ? "border-b border-primary text-primary"
                    : "text-black"
                  } py-1.5 text-sm font-bold`}
                onClick={() => {
                  dispatch(setTab("inbox"));
                }}
              >
                Inbox
              </div>
              <div
                className={`flex justify-center ${activeTab === "fax"
                    ? "border-b border-primary text-primary"
                    : "text-black"
                  } py-1.5 text-sm font-bold`}
                onClick={() => {
                  dispatch(setTab("fax"));
                }}
              >
                Sent Faxes
              </div>
            </div>
            {PageRender()}
          </div>
        </div>
      </div>
      <DeleteModal
        visible={!!deleteId}
        onCancelClick={() => setDeleteId(null)}
        onDoneClick={() => onDeleteFax(deleteId)}
      />
      {!!sentFaxData && <EditSentFaxModal
        visible={!!sentFaxData}
        setDeleteId={setDeleteId}
        setSentFaxData={setSentFaxData}
        onCancelClick={() => {
          setSentFaxData(null);
          if (activeTab === "inbox") onFaxListGet();
          if (activeTab === "fax") onSentFaxListGet();
          if (activeTab === "contacts") onContactListGet();
        }}
        data={sentFaxData}
      />}
      <DateRangePickerModal
        visible={open}
        onCancelClick={() => {
          dispatch(setSentType("Today"));
          setOpen(false);
        }}
        onDoneClick={(s: any, e: any) => {
          if (!(s && e)) {
            message.error("please select date");
            return;
          }
          setFilter({
            ...filter,
            page: "1",
            per_page: "10",
            start_date: s,
            end_date: e,
          });
          dispatch(
            setCustomeRange({
              start_date: s,
              end_date: e,
            })
          );
          dispatch(setIsCustomeRange(true));
          setOpen(false);
        }}
      />
      <DateRangePickerModal
        visible={openIn}
        onCancelClick={() => {
          dispatch(setInboxType("Today"));
          setOpenIn(false);
        }}
        onDoneClick={(s: any, e: any) => {
          if (!(s && e)) {
            message.error("please select date");
            return;
          }
          setInFilter({
            ...inFilter,
            page: "1",
            per_page: "10",
            start_date: s,
            end_date: e,
          });
          dispatch(
            setCustomeRangeIn({
              start_date: s,
              end_date: e,
            })
          );
          dispatch(setIsCustomeRangeIn(true));
          setOpenIn(false);
        }}
      />
      <WarnModal
        visible={isWarn}
        onCancelClick={() => setIsWarn(false)}
        onDoneClick={() => setIsWarn(false)}
      />
      {faxLogs?.length ? <FaxLogModal
        data={faxLogs}
        visible={faxLogs?.length}
        onCancelClick={()=> setFaxLogs(null)}
      />: null}
    </>
  );
};
export default Fax;
