import React from "react";
import {
  Button,
  CheckBox,
  Input,
  // PhoneNumberInput,
} from "components/ui-component/forms";
import { AddCoverPageModal } from "components/ui-component/modals";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  getContactList
} from "redux/slice/faxSlice";
import { useDebouncedCallback } from "use-debounce";
import { useAppDispatch } from "redux/hooks";
import { sendFax } from "redux/slice/faxSlice";
import PdfViewer from "components/ui-component/pdf-viewer/PdfViewer";
import "../../../index.css";

import add from "assets/icons/add.svg";
import closeSquare from "assets/icons/close-square.svg";
import close from "assets/icons/gray-icon/close-circle.svg";
import doc from "assets/icons/gray-icon/document.svg";
import { useAuth0 } from "@auth0/auth0-react";
import { useNumber } from "redux/slice/commanSlice";
import { message} from "antd";
import { createContact } from "redux/slice/faxSlice";

import { baseUrl } from "API/baseURL";
import { CONTACTS } from "API/constAPI";

const ComposeFax = () => {
  const { user } = useAuth0();
  const number = useNumber();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const initialData = {
    cover_to_name: "",
    to_number: "",
  };

    const [isUpload, setIsUpload] = React.useState<boolean>(false);  
    const [openModal, setOpenModal] = React.useState<boolean>(false);
    const [recipient, setRecipient] = React.useState<any>([initialData]);
    const [suggestions, setSuggestions] = React.useState<{ firstname: string; lastname: string; fax_number: string }[]>([]);
    const [showSuggestions, setShowSuggestions] = React.useState<number | null>(null);
    const [showCheckbox, setShowCheckbox] = React.useState<boolean[]>([]);
    const [namesuggestions, setNameSuggestions] = React.useState<{ firstname: string; lastname: string; fax_number: string }[]>([]);
    const [showNameSuggestions, setShowNameSuggestions] = React.useState<number | null>(null);

  const initialValues: any = {
    username: user?.email,
    from_number: number,
    to_number: "",
    notes: "",
    fax_file: [],
    cover_subject: "",
    cover_body: "",
    cover_to_name: "",
    cover_from_name: user?.name,
    addCover: false,
    cover_page: [],
  };

  const validationSchema = Yup.object<any>({
    from_number: Yup.string().required("Please enter number"),
    cover_from_name: Yup.string().required("Please enter name"),
  });

  const onSubmit = async (values: any) => {
    if (!values.fax_file.length) {
      message.error("No File Selected");
      return;
    }
    try {
      const {
        username,
        from_number,
        // to_number,
        notes,
        fax_file,
        cover_subject,
        cover_body,
        // cover_to_name,
        cover_from_name,
        cover_page,
      } = values;
      const payload = new FormData();
      payload.append("username", username);
      payload.append("from_number", from_number);
      payload.append("notes", notes);
      payload.append("cover_subject", cover_subject);
      payload.append("cover_body", cover_body);
      payload.append("cover_from_name", cover_from_name);
      for (let i = 0; i < fax_file.length; i++) {
        payload.append("fax_file", fax_file[i]);
      }
      for (let i = 0; i < fax_file.length; i++) {
        payload.append("cover_page", cover_page[i]);
      }
      let res: any;
      for (let index = 0; index < recipient.length; index++) {
        payload.append("to_number", recipient[index]?.to_number || "");
        payload.append("cover_to_name", recipient[index]?.cover_to_name || "");
        if (recipient[index]?.to_number) {
          res = await dispatch(sendFax(payload)).unwrap();
        }
      }
      if (res && res.status === "Accepted") {
        message.success("Fax sent successfully");
        navigate(-1);
      } else {
        message.error("Something went wrong");
      }
    } catch (error: any) {
      console.log("Sent fax err:---->", error?.message);
    }
  };

  const {
    values,
    touched,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const onSelectFile = async (e: any) => {
    setValues({ ...values, fax_file: e.target.files });
  };

  const onSelectCover = async (e: any) => {
    setValues({ ...values, cover_page: e.target.files });
  };

  const addMore = () => {
    setRecipient([...recipient, initialData]);
  };

  const updateCheckboxVisibility = (index: number, visibility: boolean) => {
    const newCheckboxState = [...showCheckbox];
    newCheckboxState[index] = visibility;
    setShowCheckbox(newCheckboxState);
  };


  const updateChangeData = (toNumber: any, coverToName: any, lastname: any, idx: number, flag: string) => {
    let newData = [...recipient];
    let fullName = coverToName;

    if (lastname) {
      fullName += ` ${lastname}`;
    }
    newData[idx] = { ...newData[idx], to_number: toNumber, cover_to_name: fullName };
    setRecipient(newData);
  }

  const handleChangeData = (key: string, val: string, idx: number, flag: string) => {

    let newData = [...recipient];
    newData[idx] = { ...newData[idx], [key]: val };

    if ( key === "to_number" ) {
      // const regexNumber = /^[0-9]*$/;
      // if ( !regexNumber.test( e ) ) {
      //   message.error( "Only [0-9] are allowed." );
      //   return;
      // }
      // const regexNumber = /^(?:[2-9][0-9]*)?$/; // Allow empty string or digits 2-9 for the first character followed by any digits
      // if (!regexNumber.test(e)) {
      //   message.error("Only digits 2-9 are allowed.");
      //   return;
      // }
      const firstDigit = val.charAt(0);
      if (firstDigit === "0" || firstDigit === "1") {
        message.error("Fax number cannot start with 0 or 1.");
        return;
      }
      const regexNumber = /^[0-9]*$/; // Allow only digits 0-9 for the remaining characters
      if (!regexNumber.test(val)) {
        message.error("Only digits 0-9 are allowed.");
        return;
      }
      if ( val.length > 10 ) {
        message.error( "Maximum 10 digits are allowed." );
        return;
      }
    }
    
    if ( key === "to_number" && val !== '' && flag === "false") {
        fetchContacts(val,idx,"fax_number");
    }else if(key === "cover_to_name" && val !== '' && flag === "false"){
        fetchContacts(val,idx,"name_query");
    }else{
      setTimeout(() => {
        if( key === "to_number"){
          setSuggestions([]);
          setShowSuggestions(null);
        }
        if( key === "cover_to_name"){
          setNameSuggestions([]);
          setShowNameSuggestions(null);
        }
      }, 1500);
      
    }
    setRecipient(newData);
  };

  const insertContact = async (key: string, fax_number: any, recipient: any, idx: number, flag: string) => {
    // console.log(key);
    // console.log(fax_number);
    // console.log(recipient);
    // console.log(idx);
    // console.log(flag);
    if(flag === "checked"){
      console.log("INSERT");
      try {
        const payload = {  
          "firstname": recipient,
          "lastname":"",
          "fax_number":fax_number,
          "notes":"",
        }
        console.log( "DATA" );
        console.log( payload );
        const res: any = await dispatch( createContact( payload ) ).unwrap()
        if ( res ) {
          // onYes()
          // resetForm()
          message.success( "Contact created successfully" )
        }
      } catch ( error: any ) {
        console.log( "edit sent fax err: --->", error?.message )
      }
    }else{
      const params = {
        "fax_number" : fax_number
      };
      const response: any = await dispatch(getContactList(params)).unwrap();
      console.log("DELETE");
      if(response && response?.contacts && response.contacts.length > 0){

        console.log(response.contacts[0].contact_id);
        const data = response.contacts[0].contact_id;
        const username = user?.email;
        const accessToken = localStorage.getItem("accessToken");
        fetch(`${baseUrl}${CONTACTS}/${username}/${data}`, {
          method: "DELETE",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify(data),
        })
          .then((response) => {
            if (response.ok) {
              return response.json();
            } else {
              throw new Error("Request failed with status " + response.status);
            }
          })
          .then((data) => {
            console.log("Request successful:", data);
            message.success("Contact deleted successfully");
          })
          .catch((error) => {
            console.error("Error:", error);
            message.error("Contact can't be deleted");
          });
      }
    }
    updateCheckboxVisibility(idx, false);
  }


  const fetchContacts = useDebouncedCallback(async (val: any , idx: any, key: any) => {
    console.log("CALLING FUNCTION");
    // seIsLoading(true);
    try {
      const params = {
          [key] : val
      };
      const response: any = await dispatch(getContactList(params)).unwrap();
      
      if(response && response?.contacts && response.contacts.length > 0){

        console.log(response.contacts);
        // Update suggestions state with the response data
        if(key === "fax_number"){
         setSuggestions(response.contacts);
         updateCheckboxVisibility(idx, false);
         setShowSuggestions(idx);
        }
        if(key === "name_query"){
          setNameSuggestions(response.contacts);
          setShowNameSuggestions(idx);
          updateCheckboxVisibility(idx, false);
        }
        // React.useState<{ firstname: string; lastname: string; fax_number: string }[]>([]);
        // setSuggestions( ( prevData: any ) => {
        //   return {
        //     ...prevData,
        //     suggestions: response.contacts,
        //   };
        // } );

        // Show the suggestions dropdown
        //setShowCheckbox(null);
        
      }else{
        setTimeout(() => {
          if(key === "fax_number"){
            setSuggestions([]);
            setShowSuggestions(null);
            updateCheckboxVisibility(idx, true);
          }
          if(key === "name_query"){
            setNameSuggestions([]);
            setShowNameSuggestions(null);
          }
          
        }, 1500);
        //setShowCheckbox(idx);
        
      }
      // seIsLoading(false);
    } catch (error: any) {
      // seIsLoading(false);
      console.log("contact list get error -->", error?.message);
    }
  }, 500);

  const deleteRecipient = (idx: number) => {
    let data = [...recipient];
    data.splice(idx, 1);
    setRecipient(data);
  };

  const removeFile = (idx: number) => {
    let data = [...values?.fax_file];
    data.splice(idx, 1);
    setValues({
      ...values,
      fax_file: data,
    });
  };

  const removeCover = (idx: number) => {
    let data = [...values?.cover_page];
    data.splice(idx, 1);
    setValues({
      ...values,
      cover_page: data,
    });
  };

  return (
    <>
      <div className="p-4 w-full h-[calc(100vh-55px)] smd:h-[unset]">
        <div className="bg-white px-4 py-2.5 rounded-md drop-shadow-md w-full h-full">
          <form onSubmit={handleSubmit}>
            <div className="pb-1.5 border-b-2 border-dark-800">
              <label className="text-heading font-bold text-base">
                Compose a Fax
              </label>
            </div>
            <div className="py-1.5 flex smd:flex-col">
              <div className="w-[70%] max-h-[calc(100vh-192px)] smd:max-h-[unset] overflow-y-auto border-r smd:border-0 border-dark-800 pr-2 smd:p-0 smd:w-full">
                <div className="pb-2 border-b border-dark-800">
                  <label className="text-heading text-sm font-semibold">
                    Sender
                  </label>
                  <div className="grid grid-cols-2 smd:pr-0 pr-24 gap-4 items-start smd:grid-cols-1">
                    <Input
                      label="Name"
                      placeholder="Enter your name"
                      name="cover_from_name"
                      value={values.cover_from_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      errors={errors}
                      touched={touched}
                    />
                    <Input
                      label="Fax Number"
                      placeholder="Enter fax number"
                      name="from_number"
                      readOnly
                      value={values.from_number}
                      onChange={(e: any) => {
                        let value = e.target.value;
                        let val = value.replace(/[^0-9*#+]+/g, "");
                        setFieldValue("from_number", val);
                      }}
                      onBlur={handleBlur}
                      errors={errors}
                      touched={touched}
                    />
                  </div>
                </div>
                <div className="py-2.5 border-b border-dark-800 smd:pr-0 pr-11">
                  <div className="bg-[#f8fcff] px-4 py-2">
                    <label className="text-heading text-sm font-semibold">
                      Recipient
                    </label>
                    <div className="grid grid-cols-2 smd:pr-4 pr-10 gap-4">
                      <div className="text-xs font-semibold pb-1.5 flex items-center">
                        <p>Name</p>
                      </div>
                      <div className="text-xs font-semibold pb-1.5 flex items-center">
                        <p>Fax Number</p>
                      </div>
                    </div>
                    {recipient?.map((val: any, index: number, array: any[]) => {
                      return (
                        <div key={index}>
                          <div className="grid grid-cols-2 smd:pr-4 pr-10 gap-4 pt-2">
                          <div className="relative">
                            <Input
                              label="Name"
                              isShowLabel={false}
                              className="!bg-transparent"
                              placeholder="Enter recipient name"
                              name="cover_to_name"
                              value={val.cover_to_name}
                              onChange={(e: any) =>
                                handleChangeData(
                                  "cover_to_name",
                                  e.target.value,
                                  index,
                                  "false"
                                )
                              }
                              onBlur={handleBlur}
                              errors={errors}
                              touched={touched}
                            />
                            {showNameSuggestions === index && (
                                
                                <div className="absolute top-full left-0 w-full bg-white border border-gray-300 shadow-md z-10 max-h-60 overflow-y-auto">
                                    {namesuggestions.map((item, indexs) => (
                                      <div key={indexs} className="p-2 cursor-pointer hover:bg-gray-100" onClick={() => {
                                        //handleChangeData("to_number", item.fax_number, index, "true");
                                        //handleChangeData("cover_to_name", item.firstname, index, "true");
                                        updateChangeData(item.fax_number, item.firstname, item.lastname, index, "true");
                                        setShowNameSuggestions(null);
                                      }} style={{ borderBottom: indexs < namesuggestions.length - 1 ? '1px solid #e5e7eb' : 'none' }}>
                                        <div className="flex items-center">
                                          {item.firstname ? (
                                            <div className="rounded-full h-8 w-8 flex items-center justify-center bg-gray-300 text-gray-600">
                                              {item.firstname.charAt(0)}
                                            </div>
                                          ) : (
                                            <div className="rounded-full h-8 w-8 flex items-center justify-center bg-gray-300 text-gray-600">
                                              {item.fax_number.charAt(0)}
                                            </div>
                                          )}
                                          <div className="ml-3">
                                            {item.firstname && (
                                              <div className="text-sm text-gray-800">
                                                {item.firstname} {item.lastname}
                                              </div>
                                            )}
                                            <div className="text-xs text-gray-500">{item.fax_number}</div>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                              )}
                          </div>
                            <div className="relative">
                              <Input
                                label="To Number"
                                placeholder="Enter to number"
                                name="to_number"
                                isShowLabel={false}
                                value={val.to_number}
				                        maxLength={10}
                                onChange={(e: any) => {
                                  let value = e.target.value;
                                  let vals = value.replace(/[^0-9*#+]+/g, "");
                                  handleChangeData("to_number", vals, index, "false");
                                }}
                                onBlur={handleBlur}
                                errors={errors}
                                touched={touched}
                              />
                                    {showSuggestions === index && (
                                
                                <div className="absolute top-full left-0 w-full bg-white border border-gray-300 shadow-md z-10 max-h-60 overflow-y-auto">
                                    {suggestions.map((item, indexs) => (
                                      <div key={indexs} className="p-2 cursor-pointer hover:bg-gray-100" onClick={() => {
                                        //handleChangeData("to_number", item.fax_number, index, "true");
                                        updateChangeData(item.fax_number, item.firstname, item.lastname, index, "true");
                                        setShowSuggestions(null);
                                      }} style={{ borderBottom: indexs < suggestions.length - 1 ? '1px solid #e5e7eb' : 'none' }}>
                                        <div className="flex items-center">
                                          {item.firstname ? (
                                            <div className="rounded-full h-8 w-8 flex items-center justify-center bg-gray-300 text-gray-600">
                                              {item.firstname.charAt(0)}
                                            </div>
                                          ) : (
                                            <div className="rounded-full h-8 w-8 flex items-center justify-center bg-gray-300 text-gray-600">
                                              {item.fax_number.charAt(0)}
                                            </div>
                                          )}
                                          <div className="ml-3">
                                            {item.firstname && (
                                              <div className="text-sm text-gray-800">
                                                {item.firstname} {item.lastname}
                                              </div>
                                            )}
                                            <div className="text-xs text-gray-500">{item.fax_number}</div>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                              )}
                              {showCheckbox[index] && (
                            
                            //  <Checkbox
                            //     className={`cursor-pointer absolute -right-8 top-1.5 smd:top-2 smd:-right-2 `} 
                            //     style={{ marginRight: "8px" }}
                            //     title="Check the box to save contact"
                            //     onChange={(e: any) => {
                            //       insertContact("to_number", val.to_number, val.cover_to_name, index, e.target.checked ? "checked" : "unchecked");
                            //     }}>
                                
                            //     {/* Checkbox */}
                            //   </Checkbox>
                              <img
                                src={add}
                                alt="add"
                                height="20px"
                                width="20px"
                                className="cursor-pointer absolute -right-8 top-1.5 smd:top-2 smd:-right-2"
                                style={{ marginRight: "8px" }}
                                title="Add to contacts"
                                onClick={() => {
                                    insertContact("to_number", val.to_number, val.cover_to_name, index, "checked");
                                }}
                            />
                              )}
                              <img
                                src={closeSquare}
                                alt="delete"
                                height="20px"
                                width="20px"
                                className={`cursor-pointer absolute -right-8 top-1.5 smd:top-2 smd:-right-9 ${index === 0 && "hidden"
                                  }`}
                                  //style={{ marginRight: "5px" }}
                                style={{ marginRight: showCheckbox[index] ? "-20px" : "8px" }}
                                onClick={() => deleteRecipient(index)}
                              />
                            </div>
                          </div>
                          <div
                            className={`py-2 ${array?.length - 1 !== index && "hidden"
                              }`}
                          >
                            <div className="flex">
                              <div
                                className="cursor-pointer flex gap-2 items-center"
                                onClick={() => {
                                  if (!(val?.cover_to_name || val?.to_number)) {
                                    message.error("Please fill details");
                                  } else {
                                    addMore();
                                  }
                                }}
                              >
                                <img
                                  src={add}
                                  alt="add"
                                  height="16px"
                                  width="16px"
                                />
                                <span className="text-primary text-xs font-bold">
                                  Add more
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="py-1.5 border-b border-dark-800">
                  <label className="text-heading text-sm font-semibold">
                    Cover Page
                  </label>
                  <div className="grid grid-cols-2 pt-2">
                    <CheckBox
                      label="Add Cover Page"
                      labelClassName="!text-xs"
                      value={values?.addCover}
                      checked={values?.addCover}
                      onChange={(e: any) =>
                        setFieldValue("addCover", e.target.checked)
                      }
                    />
                  </div>
                  {values?.addCover ? (
                    <div className="flex gap-5 items-center py-4">
                      <div className="flex items-center">
                        <input
                          className="w-4 h-4 text-primary bg-gray-100 border-gray-300 focus:ring-primary accent-primary"
                          type="radio"
                          name="default radio"
                          checked={!isUpload}
                          onChange={(e: any) => {
                            setIsUpload(!e.target.checked);
                          }}
                        />
                        <label className="ml-2 text-xs font-medium text-gray-900">
                          Auto Generate
                        </label>
                      </div>
                      <div className="flex items-center">
                        <input
                          className="w-4 h-4 text-primary bg-gray-100 border-gray-300 focus:ring-primary accent-primary"
                          type="radio"
                          name="default radio"
                          checked={isUpload}
                          onChange={(e: any) => {
                            setIsUpload(e.target.checked);
                          }}
                        />
                        <label className="ml-2 text-xs font-medium text-gray-900">
                          Upload
                        </label>
                      </div>
                    </div>
                  ) : null}
                  {values?.addCover && isUpload ? (
                    <div className="pb-4">
                      <label className="bg-primary text-xs px-3 py-1.5 text-white rounded-md font-bold cursor-pointer">
                        <input
                          type="file"
                          className="hidden"
			  accept="application/pdf,application/vnd.ms-excel,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/tiff,image/tif"
                          multiple
                          key={values?.cover_page?.length}
                          onChange={(e: any) => {
                            if ([...e.target.files].length) {
                              onSelectCover(e);
                            }
                          }}
                        />
                        Cover Page Upload
                      </label>
                      {values?.cover_page && [...values?.cover_page]?.length ? (
                        <div className="pt-2 flex gap-4 items-center flex-wrap">
                          {[...values?.cover_page]?.map(
                            (val: any, idx: number) => {
                              return (
                                <div
                                  className="w-[110px] flex items-center select-none justify-between"
                                  key={idx}
                                >
                                  <img src={doc} alt="doc" />
                                  <p className="max-w-[65px] text-ellipsis overflow-hidden whitespace-nowrap text-xs">
                                    {val.name}
                                  </p>
                                  <img
                                    src={close}
                                    alt="close"
                                    className="cursor-pointer"
                                    onClick={() => removeCover(idx)}
                                  />
                                </div>
                              );
                            }
                          )}
                        </div>
                      ) : null}
                    </div>
                  ) : (
                    <div className="grid grid-cols-2 smd:pr-0 pr-24 gap-4 items-start pt-4 smd:grid-cols-1">
                      <Input
                        label="Subject"
                        placeholder="Enter subject name"
                        name="cover_subject"
                        value={values?.cover_subject}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        errors={errors}
                        touched={touched}
                      />
                      <Input
                        label="Message"
                        placeholder="Enter message"
                        name="cover_body"
                        value={values?.cover_body}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        errors={errors}
                        touched={touched}
                      />
                    </div>
                  )}
                </div>
                <div className="py-1.5 border-b border-dark-800 smd:pr-0 pr-24">
                  <div>
                    <Input
                      label="Notes"
                      placeholder="Enter a note"
                      name="notes"
                      value={values?.notes}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      errors={errors}
                      touched={touched}
                    />
                  </div>
                </div>
                <div className="py-1.5 border-b border-dark-800">
                  <label className="text-heading text-sm font-semibold">
                    Upload Document
                  </label>
                  <p className="text-txt-primary text-xs max-w-[60%] pb-2 smd:max-w-[90%]">
                    {`Support file types (Max 5 Files with 25 MB total): PDF, TIF, TIFF, Microsoft Word (.doc, .docx). If needed, compress your pdf here.`}
                  </p>
                  <div className="flex flex-col items-start gap-1">
                    <label className="bg-primary text-xs px-3 py-1.5 text-white rounded-md font-bold cursor-pointer">
                      <input
                        type="file"
                        className="hidden"
                        accept="application/pdf,application/vnd.ms-excel,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/tiff,image/tif"
                        multiple
                        key={values?.fax_file?.length}
                        onChange={(e: any) => {
                          if ([...e.target.files].length) {
                            onSelectFile(e);
                          }
                        }}
                      />
                      Select Document
                    </label>
                    {values?.fax_file && [...values?.fax_file]?.length ? (
                      <div className="pt-2 flex gap-4 items-center flex-wrap">
                        {[...values?.fax_file]?.map((val: any, idx: number) => {
                          return (
                            <div
                              className="w-[110px] flex items-center select-none justify-between"
                              key={idx}
                            >
                              <img src={doc} alt="doc" />
                              <p className="max-w-[65px] text-ellipsis overflow-hidden whitespace-nowrap text-xs">
                                {val.name}
                              </p>
                              <img
                                src={close}
                                alt="close"
                                className="cursor-pointer"
                                onClick={() => removeFile(idx)}
                              />
                            </div>
                          );
                        })}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="w-[30%] smd:w-full smd:pt-4">
                <div className="px-4 smd:px-0">
                  <PdfViewer selectFile={values.fax_file} />
                </div>
              </div>
            </div>
            <div className="pt-2 border-t-2 border-dark-800 ">
              <div className="flex gap-2 justify-end items-center">
                <Button
                  text="Cancel"
                  styles="secondary-outline"
                  onClick={() => navigate(-1)}
                />
                <Button text="Send" className="!py-2" type="submit" />
              </div>
            </div>
          </form>
        </div>
      </div>
      <AddCoverPageModal
        visible={openModal}
        onCancelClick={() => {
          setOpenModal(false);
        }}
      />
    </>
  );
};

export default ComposeFax;
