import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useErrorDetails } from "redux/slice/commanSlice";

const InfoError = () => {
    const errorDetails = useErrorDetails();

    return (
        <div className="h-[calc(100vh-60px)] smd:px-[10px]">
            <div className="h-full flex justify-center items-center">
                <div className="flex justify-center items-center flex-col gap-4 w-[450px] smd:w-[400px] py-6 bg-error bg-opacity-30 rounded-lg">
                    <FontAwesomeIcon
                        className="cursor-pointer"
                        color="#E7515A"
                        icon={faCircleExclamation}
                        size="4x"
                    />
                    <span className="text-xl font-extrabold text-white capitalize">
                        {errorDetails?.error || "Invalid User"}
                    </span>
                    <div
                        className="px-4 text-sm text-justify text-secondary font-medium infoMsg"
                        dangerouslySetInnerHTML={{ __html: errorDetails["message"] }}
                    ></div>
                </div>
            </div>
        </div>
    );
};

export default InfoError;

