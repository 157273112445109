import { Route, Routes, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Header from "../Header";
import Dashboard from "pages/dashboard";
import Fax from "pages/fax";
import ComposeFax from "pages/fax/composeFax";
import { useAuth0 } from "@auth0/auth0-react";
import Loader from "components/ui-component/loader/Loader";
import { useAppDispatch } from "redux/hooks";
import {
    clearCommonSlice,
    setAccessToken,
    setLoading,
    setUserInfo,
    useAccessToken,
    useIsError,
    useLoading,
} from "redux/slice/commanSlice";
import { useEffect, useState } from "react";
import InfoError from "pages/infoError";
import { clearSlice } from "redux/slice/faxSlice";
import TryAgainLogin from "pages/tryAgainLogin/tryAgainLogin"

const MainLayout = () => {
    const {
        user,
        isAuthenticated,
        isLoading,
        loginWithRedirect,
        logout,
        getAccessTokenSilently,
    } = useAuth0();

    const [searchParams] = useSearchParams()
    const isIframe = searchParams.get("is_iframe")
    console.log('isIframe',isIframe)
    const [iframeState, setIframeState] = useState(isIframe)

    const dispatch = useAppDispatch();
    const navigate = useNavigate()

    const accessToken = useAccessToken();
    const location = useLocation();
    const pathName = location.pathname;
    const isError = useIsError();
    const loading = useLoading();

    const navigation = useNavigate();

    const AuthHeader = async () => {
        try {
            const audience = process.env.REACT_APP_AUTH0_AUDIENCE;
            const accessToken: any = await getAccessTokenSilently({
                authorizationParams: {
                    audience: audience,
                    scope: "read:current_user",
                },
            });
            dispatch(setAccessToken(accessToken));
            localStorage.setItem("accessToken", accessToken);
        } catch (error: any) {
            console.log("ERROR");
            console.log(error);
            // navigate(`/relogin${window.location.search}`)
            //return true;
            if (error.message === "Login required" && !isAuthenticated) {
               
                const params = new URLSearchParams(window.location.search);
                const error = params.get("error");
                // const errorDescription = params.get("error_description");

                if (error === "access_denied") {
                    navigate(`/relogin${window.location.search}`)

                }else{
                    loginWithRedirect();
                    dispatch(setUserInfo(true));
                }
            } else if (
                error?.message === "We could not locate your OneCloud Account"
            ) {
                logout();
                localStorage.clear();
                dispatch(clearSlice());
                dispatch(clearCommonSlice());
            } else {
                // logout();
                // localStorage.clear();
                // dispatch(clearSlice());
                // dispatch(clearCommonSlice());
                // navigate(`/relogin${window.location.search}`)

                const params = new URLSearchParams(window.location.search);
                const error = params.get("error");
                // const errorDescription = params.get("error_description");

                if (error === "access_denied") {
                    navigate(`/relogin${window.location.search}`)

                }else{
                    logout();
                    localStorage.clear();
                    dispatch(clearSlice());
                    dispatch(clearCommonSlice());
                }
            }
        }
    };

    useEffect(()=>{
        if(isIframe){
            setIframeState(isIframe)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[isIframe])

    useEffect(() => {
        AuthHeader();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getAccessTokenSilently, user?.sub, isAuthenticated]);

    useEffect(() => {
        if (isError) {
            navigation("/invalid-user");
        } else if (pathName === "/invalid-user") {
            navigation("/");
        } else if (accessToken) {
            dispatch(setLoading(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isError, pathName]);

    if (isLoading)
        return (
            <div className="h-[100vh]">
                <Loader />
            </div>
        );

    return (
        <>
            {isAuthenticated && !!accessToken ? (
                <>
                {!Boolean(iframeState) && (
                    <Header />
                )}
                    {/* <Sidebar /> */}
                    {loading ? (
                        <div className="h-[100vh]">
                            <Loader />
                        </div>
                    ) : (
                        <div className={`${iframeState ? 'pt-[0]': 'pt-[55px]' } pr-[10px] min-h-[100vh] overflow-auto smd:pr-0`}>
                            <Routes>
                                <Route path="/invalid-user" element={<InfoError />} />
                                <Route path="/dashboard" element={<Dashboard />} />
                                <Route path="/" element={<Fax />} />
                                <Route path="/fax" element={<Fax />} />
                                <Route path="/fax/compose-fax" element={<ComposeFax />} />
                                {/* <Route path="/relogin" element={<TryAgainLogin />} /> */}
                            </Routes>
                        </div>
                    )}
                </>
            ) : null}
            <Routes>
                <Route path="/relogin" element={<TryAgainLogin />} />
            </Routes>
        </>
    );
};
export default MainLayout;
