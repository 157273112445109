// PROJECT IMPORTS
import ConfirmationModal from "./ConfirmationModal";

// TYPES
interface DeleteProps {
  visible: boolean;
  isLoading?: boolean;
  onCancelClick?: any;
  onDoneClick?: any;
}

/* ============================== DELETE MODEL ============================== */

const DeleteModal = (props: DeleteProps) => {
  const {
    visible = false,
    isLoading = false,
    onCancelClick,
    onDoneClick,
  } = props;

  return (
    <>
      <ConfirmationModal
        isLoading={isLoading}
        visible={visible}
        title="Are you sure you want to delete this fax?"
        content="This action cannot be undone. Deleting the fax will permanently remove all its content and cannot be recovered."
        onCancelClick={onCancelClick}
        onDoneClick={onDoneClick}
      />
    </>
  );
};

export default DeleteModal;
