function Auth0Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="22">
      <path
        d="M15.084 17.797L12.952 11l5.582-4.2h-6.9L9.5.002V0h6.9l2.136 6.8.002-.001c1.238 3.944-.038 8.43-3.453 11zm-11.166 0l-.002.002L9.5 22l5.584-4.202-5.583-4.202-5.583 4.201zm-3.45-11c-1.305 4.159.209 8.564 3.449 11.001v-.002L6.05 11 .47 6.8h6.898L9.5.002V0H2.6L.467 6.798z"
        fill="#A5B8D1"
      />
    </svg>
  )
}

export default Auth0Icon
