/* eslint-disable array-callback-return */
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";

import {
    getUserInfo,
    useInputContactDetails,
    getContactList
} from "redux/slice/faxSlice";
import { useAppDispatch } from "redux/hooks";
import {
    setErrorPageData,
    setIsErrorPage,
    setLoading,
    setUserInfo,
} from "redux/slice/commanSlice";
import {  faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Input } from "components/ui-component/forms";

import Loader from "components/ui-component/loader/Loader";
import Table from "components/ui-component/Table/Table";

import edit from "assets/icons/gray-icon/edit.svg";
import nodata from "assets/images/no-data.svg";
import trash from "assets/icons/trash.svg";
import lock from "assets/icons/lock.svg";
// import searchIcon from "assets/icons/search.svg";

import { message } from "antd";
import Pagination from "components/ui-component/Table/Pagination";
import { useAuth0 } from "@auth0/auth0-react";
import EditContactModal from "components/Modal/EditContactModal"
import ConfirmModal from "components/Modal/ConfirmModal"

import { baseUrl } from "API/baseURL";
import { CONTACTS } from "API/constAPI";


interface SentContactsProps {
    contactfilter: any;
    setContactFilter: any;
    setDeleteId: any;
    setSentFaxData: any;
    setIsWarn: any;
    isLoading: boolean;
    setOpenDate: any;
    setFaxLogs: any;
}

const Contacts = (props: SentContactsProps) => {
    const {
        setDeleteId,
        setSentFaxData,
        setContactFilter,
        contactfilter,
        // setIsWarn,
        // setOpenDate,
        // setFaxLogs,
    } = props;

    const { user } = useAuth0();
    const navigation = useNavigate();
    const ContactList = useInputContactDetails();
    // const sentType = useSentType();
    // const status_filter = useStatusfilter();
    // const hide_deleted = useHideDelete();
    // const customRange = useCustomeRange();
    // const isCustomRange = useIsCustomeRange();
    // const numberDetails = useNumberDetails();
    const [isLoading, seIsLoading] = React.useState<boolean>(false);

    const [ isOpen, setIsOpen ] = React.useState<boolean>( false )
    const [ isEdit, setIsEdit ] = React.useState<boolean>( false )
    const [ data, setData ] = React.useState<any>( null )

    const dispatch = useAppDispatch();
    const [ deleteContact, setDeleteContact ] = React.useState<string | null>( null )
    // const [open, setOpen] = React.useState<boolean>(false);
    const [search, setSearch] = React.useState<boolean>(
        contactfilter.search_query === "" ? false : true
        //contactfilter.firstname_query === "" ? false : true
    );

    // const [filter, setFilter] = React.useState<any>({
    //     page: "1",
    //     per_page: "10",
    //     search_query: "",
    //     order_by: "desc",
    //   });
        useEffect( () => {
            onContactListGet();
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [] );
    
      const onContactListGet = useDebouncedCallback(async () => {
        console.log("CALLING FUNCTION");
        onGetUserInfo();
        seIsLoading(true);
        try {
          const params = {
            ...contactfilter
          };
          await dispatch(getContactList(params)).unwrap();
          seIsLoading(false);
        } catch (error: any) {
          seIsLoading(false);
          console.log("contact list get error -->", error?.message);
        }
      }, 500);
   

    

    const onDeleteContact = async (id: any) => {
        onGetUserInfo();
        console.log("DELETE CALL");
        console.log(id);
        const data = [id];
        const username = user?.email;
        const accessToken = localStorage.getItem("accessToken");
        fetch(`${baseUrl}${CONTACTS}/${username}/${id}`, {
          method: "DELETE",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify(data),
        })
          .then((response) => {
            if (response.ok) {
              return response.json();
            } else {
              throw new Error("Request failed with status " + response.status);
            }
          })
          .then((data) => {
            console.log("Request successful:", data);
            message.success("Contact deleted successfully");
            setDeleteContact( "" )
            onContactListGet()
          })
          .catch((error) => {
            console.error("Error:", error);
            setDeleteContact( "" )
            message.error("Contact can't be deleted");
          });
      };

   


    const onGetUserInfo = async () => {
        try {
            const username = user?.email;
            if (username !== undefined) {
                const res: any = await dispatch(getUserInfo(username)).unwrap();
                // linkAccount();
                if (res.error === "invalid user") {
                    dispatch(setErrorPageData(res));
                    dispatch(setIsErrorPage(true));
                    navigation("/invalid-user");
                    dispatch(setLoading(false));
                }
                dispatch(setUserInfo(false));
                dispatch(setLoading(false));
            } else {
                dispatch(setUserInfo(false));
                dispatch(setLoading(false));
            }
        } catch (error: any) {
            dispatch(setIsErrorPage(true));
            navigation("/invalid-user");
            dispatch(setLoading(false));
            console.log("Get USer Info Err: --->", error?.message);
        }
    };



   

    const columns = [
        {
            name: "First Name",
            selector: (row: any) => row.firstname,
            sortable: true,
            width: "140px"
        },
        {
            name: "Last Name",
            selector: (row: any) => row.lastname,
            sortable: true,
            width: "120px"
        },
        {
            name: "Number",
            selector: (row: any) => row.fax_number,
            sortable: true,
            width: "180px"
        },
        {
            name: "Notes",
            selector: (row: any) => row.notes,
            sortable: true,
            width:"400px"
        },
        {
            name: "",
            right: "true",
            cell: (row: any) => {
                return (
                    <div className="flex gap-3 items-center justify-end text-end">
                       
                        <div className="cursor-pointer" onClick={() => {
                setIsEdit( true );
                setData( row );
                setIsOpen( true );
              }}>
                            <img src={edit} alt="edit"  width="17px" height="17px"/>
                        </div>
                        <div
                            className="cursor-pointer"
                            onClick={() => {
                                setDeleteContact( row?.contact_id )
                              }}
                        >
                            <img src={trash} alt="trash" width="16px" height="16px" />
                        </div>
                    </div>
                );
            },
        },
    ];

    return (
        <>
            <div>
                <div className="flex justify-between pb-2 px-1 smd:gap-y-3 smd:px-2 smd:shadow-lg smd:bg-white">
                    <div className="flex gap-4 items-center smd:flex-wrap">
                        <div className="flex items-center gap-2">

                            <label className="text-sm font-bold text-heading">
                                Contacts
                            </label>
                        </div>
                    </div>
                    <div className="flex items-center gap-2 smd:justify-end">
                        <div className="border-dark-700 border px-1.5 py-0.5 rounded-md cursor-pointer flex items-center">
                            <div
                                className={`${search ? "border-r border-dark-700 pr-1.5" : ""}`}
                            >
                                <FontAwesomeIcon
                                    className="cursor-pointer"
                                    color="#8083A3"
                                    icon={faSearch}
                                    onClick={() => {
                                        if (search) {
                                            setContactFilter({
                                                ...contactfilter,
                                                search_query: "",
                                                //firstname_query: "",
                                            });
                                        }
                                        setSearch(!search);
                                    }}
                                />
                            </div>
                            <Input
                                name="search"
                                isShowLabel={false}
                                value={contactfilter.search_query}
                                //value={contactfilter.firstname_query}
                                placeholder="Search by firstname, lastname, notes and name"
                                className={`${!search && "hidden"
                                    } transition ease-in-out delay-1000 resize-none rounded-md w-[275px] !py-[5px] focus:outline-none !border-none`}
                                onChange={(e: any) => {
                                    setContactFilter({
                                        ...contactfilter,
                                        search_query: e.target.value,
                                        //firstname_query: e.target.value,
                                    });
                                }}
                            />
                        </div>
                        <Button
                            className="smd:hidden"
                            text="Add Contact"
                            icon="add-white"
                            onClick={() => setIsOpen( true )}
                        />

                    </div>
                </div>
                <div className="hidden smd:block h-[calc(100vh-152px)] bg-[#F7FAFD] overflow-y-auto pb-[55px]">
                    {isLoading ? (
                        <Loader />
                    ) : ContactList?.contacts?.length ? (
                        <div className="">
                            {ContactList?.contacts.map((val: any, index: number) => {
                                return (
                                    <div className="p-2" key={index}>
                                        <div className="bg-white p-4 rounded-lg drop-shadow-sm">
                                            <div className="grid grid-cols-2 items-center">
                                                <div className="flex gap-3 items-center">
                                                    <label className="text-sm">To: {val.to_number}</label>
                                                </div>
                                                <div className="flex gap-1.5 items-center justify-end">
                                                    <img
                                                        width="15px"
                                                        height="15px"
                                                        className="cursor-pointer"
                                                        src={lock}
                                                        alt="lock"
                                                        title="To ensure the utmost security and confidentiality, all faxes are subjected to encryption before transmission"
                                                    />
                                                    <div
                                                        className={`${val?.status === "sending"
                                                                ? "bg-primary"
                                                                : val?.status === "delivered" ||
                                                                    val?.status === "complete"
                                                                    ? "bg-primary-green"
                                                                    : val?.status === "deleted"
                                                                        ? "bg-layout"
                                                                        : "bg-[#FF455C]"
                                                            } rounded-full h-3 w-3`}
                                                    ></div>
                                                    <div
                                                        className={`${val?.status === "sending"
                                                                ? "text-primary"
                                                                : val?.status === "delivered" ||
                                                                    val?.status === "complete"
                                                                    ? "text-primary-green"
                                                                    : val?.status === "deleted"
                                                                        ? "text-layout"
                                                                        : "text-[#FF455C]"
                                                            } text-sm capitalize`}
                                                    >
                                                        {val?.status}
                                                    </div>
                                                  
                                                </div>
                                            </div>
                                            <div className="grid grid-cols-2 items-center pt-2">
                                                <label className="text-sm">
                                                    From: {val.from_number || ""}
                                                </label>
                                                <label className="text-sm flex justify-end">
                                                    {val.created_on || ""}
                                                </label>
                                            </div>
                                            <div className="pt-2">
                                                <label className="text-sm font-semibold">
                                                    {val.notes || ""}
                                                </label>
                                            </div>
                                            <div className="pt-2 flex items-center justify-between">
                                                {/* {val?.fax_log && val?.fax_log?.length ? (
                                                    <div
                                                        className="flex items-center gap-2 cursor-pointer"
                                                        onClick={() => {
                                                            setFaxLogs(val?.fax_log);
                                                        }}
                                                    >
                                                        <img
                                                            src={searchIcon}
                                                            alt="search"
                                                            height={15}
                                                            width={15}
                                                        />
                                                        <span className="text-[13px] pt-0.5 text-black">
                                                            Fax Log
                                                        </span>
                                                    </div>
                                                ) : null} */}
                                              
                                                <div
                                                    className="flex items-center gap-2 cursor-pointer"
                                                    onClick={() => {
                                                        setSentFaxData(val);
                                                    }}
                                                >
                                                    <img src={edit} alt="edit" />
                                                    <span className="text-[13px] pt-0.5 text-black">
                                                        Edit
                                                    </span>
                                                </div>
                                                
                                                {val?.status !== "deleted" && (
                                                    <div
                                                        className="flex items-center gap-2 cursor-pointer"
                                                        onClick={() => {
                                                            setDeleteId(val?.uuid);
                                                        }}
                                                    >
                                                        <img
                                                            src={trash}
                                                            alt="trash"
                                                            width="14px"
                                                            height="14px"
                                                        />
                                                        <span className="text-[13px] pt-0.5 text-error">
                                                            Delete
                                                        </span>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                            <div className="absolute bottom-0 w-full">
                                <Pagination
                                    count={ContactList?.contacts?.length || 0}
                                    totalCount={ContactList?.total_contacts || 0}
                                    page={contactfilter?.page}
                                    limit={contactfilter?.per_page}
                                    onPageClick={(page: number) => {
                                        setContactFilter({
                                            ...contactfilter,
                                            page,
                                        });
                                    }}
                                    onLimitChange={(limit: number) => {
                                        setContactFilter({
                                            ...contactfilter,
                                            per_page: limit,
                                            page: 1,
                                        });
                                    }}
                                    firstPageClick={() => {
                                        setContactFilter({
                                            ...contactfilter,
                                            page: 1,
                                        });
                                    }}
                                    lastPageClick={() => {
                                        setContactFilter({
                                            ...contactfilter,
                                            page: Math.ceil(
                                                ContactList?.total_contacts / contactfilter.per_page
                                            ),
                                        });
                                    }}
                                />
                            </div>
                        </div>
                    ) : (
                        <div className="flex flex-col items-center justify-center gap-2 h-full">
                            <img src={nodata} alt="no data" height={100} width={100} />
                            <label className="text-base font-bold text-black">
                                No Contacts Found
                            </label>
                        </div>
                    )}
                </div>
                <div className="smd:hidden">
                    <Table
                        isLoading={isLoading}
                        columns={columns}
                        data={ContactList?.contacts?.length ? ContactList?.contacts : []}
                        totalCount={ContactList?.total_contacts || 0}
                        page={contactfilter?.page}
                        limit={contactfilter?.per_page}
                        onPageClick={(page: number) => {
                            setContactFilter({
                                ...contactfilter,
                                page,
                            });
                        }}
                        onLimitChange={(limit: number) => {
                            setContactFilter({
                                ...contactfilter,
                                per_page: limit,
                                page: 1,
                            });
                        }}
                    />

                    <ConfirmModal
                            isOpen={!!deleteContact}
                            isLoading={isLoading}
                            closeModal={() => {
                                setDeleteContact( null )
                            }}
                            onYes={() => onDeleteContact( deleteContact )}
                            title="Are you sure you want to delete this contact?"
                            //description="This action cannot be undone. Deleting the phone number will permanently remove all its content and cannot be recovered."
                        />
                    <EditContactModal
                        isOpen={isOpen}
                        data={data}
                        isEdit={isEdit}
                        closeModal={() => {
                            setIsEdit( false )
                            setIsOpen( false )
                            setData( null )
                          }}
                          onYes={async () => {
                            await onContactListGet();
                            setIsEdit( false )
                            setIsOpen( false )
                            setData( null )
                          }}
                      />
                </div>
            </div>
        </>
    );
};

export default Contacts;
