import { Select } from "components/ui-component/forms";
import { StylesConfig } from "react-select";
import { useAppDispatch } from "redux/hooks";
import { setStatusFilter, useStatusfilter } from "redux/slice/commanSlice";

// CUSTOM STYLE
const customStyles: StylesConfig<any> = {
    control: (styles: any) => {
        return {
            ...styles,
            height: "28px",
            minHeight: "28px",
            borderColor: "#646567",
            // borderWidth: "0 0 1px 0",
            borderRadius: "6px",
            boxShadow: "none",
            padding: "0px 3px",
            color: "#646567",
            fontWeight: "600",

            ":hover": {
                borderColor: "#646567",
            },
        };
    },
    input: (styles: any) => {
        return {
            ...styles,
            margin: "0",
        };
    },
    placeholder: (styles: any) => {
        return {
            ...styles,
            color: "#B2B3B5",
        };
    },
    valueContainer: (styles: any) => {
        return {
            ...styles,
            padding: "0px",
        };
    },
    dropdownIndicator: (styles: any) => {
        return {
            ...styles,
            padding: "0px 0px",
            color: "#646567",
        };
    },
    indicatorSeparator: (styles: any) => {
        return {
            ...styles,
            display: "none",
        };
    },
    clearIndicator: (styles: any) => {
        return {
            ...styles,
            padding: "0px",
        };
    },
    groupHeading: (styles: any) => {
        return {
            ...styles,
            color: "#13151F",
            fontWeight: 700,
            fontSize: "10px",
        };
    },
    option: (styles: any, state) => {
        return {
            ...styles,
            backgroundColor: state.isSelected
                ? "#E2F6FF"
                : state.isFocused
                    ? "#E2F6FF"
                    : "white",
            fontWeight: state.isSelected ? 700 : 400,
            color: state.isSelected ? "#38A6DE" : "#646567",
            ":active": {
                backgroundColor: "#E2F6FF",
            },
        };
    },
    menuList: (styles: any) => {
        return {
            ...styles,
            borderColor: "white",
            borderWidth: "0",
            borderRadius: "6px",
            padding: "0",
        };
    },
    menu: (styles: any) => {
        return {
            ...styles,
            borderColor: "white",
            zIndex: 2,
        };
    },
};

const StatusOption = [
    {
        label: "All Faxes",
        value: "all",
    },
    {
        label: "Delivered Faxes",
        value: "delivered",
    },
    {
        label: "Sending Faxes",
        value: "sending",
    },
    {
        label: "Failed Faxes",
        value: "failed",
    },
];

/* ==============================  OPTIONS ============================== */

const Options = () => {
    const dispatch = useAppDispatch();
    const status_filter = useStatusfilter();
    return (
        <>
            <Select
                name="status_filter"
                options={StatusOption}
                value={status_filter}
                isShowLabel={false}
                className="w-full"
                styles={customStyles}
                onChange={(e: any) => {
                    dispatch(setStatusFilter(e.target.value));
                }}
            />
        </>
    );
};

export default Options;
