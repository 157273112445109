import { useEffect, useState } from "react"

const useScreenDetector = () => {
  const [width, setWidth] = useState(window.innerWidth)

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange)

    return () => {
      window.removeEventListener("resize", handleWindowSizeChange)
    }
  }, [])

  const isSmallMobile = width <= 425
  const isMobile = width < 768 && width > 425
  const isTablet = width < 1024 && width >= 768
  const isLargeTablet = width < 1260 && width >= 1024
  const isDesktop = width >= 1260

  return { isSmallMobile, isMobile, isTablet, isLargeTablet, isDesktop }
}

export default useScreenDetector
