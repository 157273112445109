import BrandLogoIcon from "../Icons/BrandLogo"
import LogoIcon from "../Icons/LogoIcon"

interface LogoProps {
  logoIcon?: boolean
  style?: Record<string, string>
}

function BrandLogo(props: LogoProps) {
  const { logoIcon, style } = props
  return (
    <div
      className="brandlogo"
      style={{ width: "100px", textAlign: "center", ...style }}
    >
      {!logoIcon ? <BrandLogoIcon /> : <LogoIcon />}
    </div>
  )
}

export default BrandLogo
