// TYPES
interface TextareaProps {
    className?: string;
    label?: string;
    rows?: number;
    name: string;
    value?: string | number;
    placeholder?: string;
    tooltipRight?: boolean;
    isInfo?: boolean;
    isShowLabel?: boolean;
    touched?: any;
    errors?: any;
    onChange?: any;
    disabled?: boolean;
    onFocus?: any;
    onBlur?: any;
}

/* ============================== TEXTAREA ============================== */

const Textarea = (props: TextareaProps) => {
    const {
        className,
        label,
        name,
        rows = 3,
        value,
        placeholder,
        disabled,
        isShowLabel = true,
        touched,
        errors,
        onChange,
        onFocus,
        onBlur,
    } = props;

    return (
        <div>
            {isShowLabel && (
                <div className="text-xs font-semibold pb-2 flex items-center">
                    <p>{label ? label : name}</p>
                </div>
            )}
            <div className="flex flex-row items-center relative">
                <textarea
                    className={`${className} focus:outline-none border-2 rounded-md focus:border-primary text-xs py-2.5 w-full pl-5 pr-5
                        ${touched && errors && touched[name] && errors[name]
                            ? "border-error"
                            : "border-dark-700"
                        }`}
                    rows={rows}
                    name={name}
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                    disabled={disabled}
                    onBlur={onBlur}
                    onFocus={onFocus}
                />
            </div>
            {touched && errors && touched[name] && errors[name] && (
                <span className="text-error font-normal text-[10px]">
                    {errors[name]}
                </span>
            )}
        </div>
    );
};

export default Textarea;
