import { useMemo } from "react";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useAppSelector } from "redux/hooks";
import { RootState } from "redux/store";
import { format } from "date-fns";

interface InitialState {
  sidebarOpen: boolean;
  activeTab: string;
  sentType: string;
  inboxType: string;
  isUserInfo: boolean;
  status_filter: string;
  hide_deleted: string;
  hide_deletedIn: string;
  customRange: any;
  customRangeIn: any;
  isCustomRange: boolean;
  isCustomRangeIn: boolean;
  accessToken: any;
  isError: boolean;
  errorDetails: any;
  loading: boolean;
  number: string;
}

/* ============================== COMMAN SLICE ============================== */

const initialState: InitialState = {
  sidebarOpen: false,
  activeTab: "inbox",
  inboxType: "All",
  sentType: "All",
  status_filter: "all",
  isUserInfo: false,
  hide_deleted: "yes",
  hide_deletedIn: "yes",
  customRange: {
    start_date: format(new Date().setHours(0, 0, 1), "yyyy-MM-dd HH:mm:ss"),
    end_date: format(new Date().setHours(23, 59, 59), "yyyy-MM-dd HH:mm:ss"),
  },
  isCustomRange: false,
  customRangeIn: {
    start_date: format(new Date().setHours(0, 0, 1), "yyyy-MM-dd HH:mm:ss"),
    end_date: format(new Date().setHours(23, 59, 59), "yyyy-MM-dd HH:mm:ss"),
  },
  isCustomRangeIn: true,
  accessToken: null,
  isError: false,
  loading: true,
  errorDetails: {},
  number: ""
};

const commanSlice = createSlice({
  name: "comman",
  initialState,
  reducers: {
    setTab(state, action: PayloadAction<any>) {
      state.activeTab = action.payload;
    },
    setSidebar(state, action: PayloadAction<any>) {
      state.sidebarOpen = action.payload;
    },
    setInboxType(state, action: PayloadAction<any>) {
      state.inboxType = action.payload;
    },
    setSentType(state, action: PayloadAction<any>) {
      state.sentType = action.payload;
    },
    setUserInfo(state, action: PayloadAction<any>) {
      state.isUserInfo = action.payload;
    },
    setStatusFilter(state, action: PayloadAction<any>) {
      state.status_filter = action.payload;
    },
    setDeleteHide(state, action: PayloadAction<any>) {
      state.hide_deleted = action.payload;
    },
    setDeleteHideIn(state, action: PayloadAction<any>) {
      state.hide_deletedIn = action.payload;
    },
    setCustomeRange(state, action: PayloadAction<any>) {
      state.customRange = action.payload;
    },
    setCustomeRangeIn(state, action: PayloadAction<any>) {
      state.customRangeIn = action.payload;
    },
    setIsCustomeRange(state, action: PayloadAction<any>) {
      state.isCustomRange = action.payload;
    },
    setIsCustomeRangeIn(state, action: PayloadAction<any>) {
      state.isCustomRangeIn = action.payload;
    },
    setAccessToken(state, action: PayloadAction<any>) {
      state.accessToken = action.payload;
    },
    setIsErrorPage(state, action: PayloadAction<any>) {
      state.isError = action.payload;
    },
    setErrorPageData(state, action: PayloadAction<any>) {
      state.errorDetails = action.payload;
    },
    setLoading(state, action: PayloadAction<any>) {
      state.loading = action.payload;
    },
    setNumber(state, action: PayloadAction<any>) {
      state.number = action.payload;
    },
    clearCommonSlice: () => initialState,
  },
});

export default commanSlice.reducer;

export const {
  setSidebar,
  setTab,
  setSentType,
  setInboxType,
  setUserInfo,
  setStatusFilter,
  setDeleteHide,
  setDeleteHideIn,
  setCustomeRange,
  setIsCustomeRange,
  setCustomeRangeIn,
  setIsCustomeRangeIn,
  setAccessToken,
  setIsErrorPage,
  setLoading,
  setNumber,
  setErrorPageData,
  clearCommonSlice,
} = commanSlice.actions;

export const selectTab = (state: RootState) => state.comman.activeTab;
export const useTab = () => {
  const activeTab = useAppSelector(selectTab);
  return useMemo(() => activeTab, [activeTab]);
};

export const selectInboxType = (state: RootState) => state.comman.inboxType;
export const useInboxType = () => {
  const inboxType = useAppSelector(selectInboxType);
  return useMemo(() => inboxType, [inboxType]);
};

export const selectSentType = (state: RootState) => state.comman.sentType;
export const useSentType = () => {
  const sentType = useAppSelector(selectSentType);
  return useMemo(() => sentType, [sentType]);
};

export const selectIsUserInfo = (state: RootState) => state.comman.isUserInfo;
export const useIsUserInfo = () => {
  const isUserInfo = useAppSelector(selectIsUserInfo);
  return useMemo(() => isUserInfo, [isUserInfo]);
};

export const selectStatus = (state: RootState) => state.comman.status_filter;
export const useStatusfilter = () => {
  const status_filter = useAppSelector(selectStatus);
  return useMemo(() => status_filter, [status_filter]);
};

export const selectHideDelete = (state: RootState) => state.comman.hide_deleted;
export const useHideDelete = () => {
  const hide_deleted = useAppSelector(selectHideDelete);
  return useMemo(() => hide_deleted, [hide_deleted]);
};

export const selectHideDeleteIn = (state: RootState) =>
  state.comman.hide_deletedIn;
export const useHideDeleteIn = () => {
  const hide_deletedIn = useAppSelector(selectHideDeleteIn);
  return useMemo(() => hide_deletedIn, [hide_deletedIn]);
};

export const selectCustomeRange = (state: RootState) =>
  state.comman.customRange;
export const useCustomeRange = () => {
  const customRange = useAppSelector(selectCustomeRange);
  return useMemo(() => customRange, [customRange]);
};

export const selectIsCustomeRange = (state: RootState) =>
  state.comman.isCustomRange;
export const useIsCustomeRange = () => {
  const isCustomRange = useAppSelector(selectIsCustomeRange);
  return useMemo(() => isCustomRange, [isCustomRange]);
};

export const selectCustomeRangeIn = (state: RootState) =>
  state.comman.customRangeIn;
export const useCustomeRangeIn = () => {
  const customRangeIn = useAppSelector(selectCustomeRangeIn);
  return useMemo(() => customRangeIn, [customRangeIn]);
};

export const selectIsCustomeRangeIn = (state: RootState) =>
  state.comman.isCustomRangeIn;
export const useIsCustomeRangeIn = () => {
  const isCustomRangeIn = useAppSelector(selectIsCustomeRangeIn);
  return useMemo(() => isCustomRangeIn, [isCustomRangeIn]);
};

export const selectAccessToken = (state: RootState) => state.comman.accessToken;
export const useAccessToken = () => {
  const accessToken = useAppSelector(selectAccessToken);
  return useMemo(() => accessToken, [accessToken]);
};

export const selecIsError = (state: RootState) => state.comman.isError;
export const useIsError = () => {
  const isError = useAppSelector(selecIsError);
  return useMemo(() => isError, [isError]);
};

export const selectLoading = (state: RootState) => state.comman.loading;
export const useLoading = () => {
  const loading = useAppSelector(selectLoading);
  return useMemo(() => loading, [loading]);
};

export const selectErrorDetails = (state: RootState) =>
  state.comman.errorDetails;
export const useErrorDetails = () => {
  const errorDetails = useAppSelector(selectErrorDetails);
  return useMemo(() => errorDetails, [errorDetails]);
};

export const selectSidebar = (state: RootState) =>
  state.comman.sidebarOpen;
export const useSidebarOpen = () => {
  const sidebarOpen = useAppSelector(selectSidebar);
  return useMemo(() => sidebarOpen, [sidebarOpen]);
};


export const selectNumber = (state: RootState) =>
  state.comman.number;
export const useNumber = () => {
  const number = useAppSelector(selectNumber);
  return useMemo(() => number, [number]);
};
