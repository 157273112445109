// PROJECT IMPORTS
import { Input } from "../forms";
import ConfirmationModal from "./ConfirmationModal";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useAppDispatch } from "redux/hooks";
import { getUserInfo, userMigration } from "redux/slice/faxSlice";
import { message } from "antd";
import { useAuth0 } from "@auth0/auth0-react";
import {
    setErrorPageData,
    setIsErrorPage,
    setLoading,
    setUserInfo,
} from "redux/slice/commanSlice";
import { useNavigate } from "react-router-dom";

// TYPES
interface MigrationFormModalProps {
    visible: boolean;
    isLoading?: boolean;
    onCancelClick?: any;
    onDoneClick?: any;
}

/* ============================== DELETE MODEL ============================== */

const MigrationFormModal = (props: MigrationFormModalProps) => {
    const {
        visible = false,
        isLoading = false,
        onCancelClick,
        onDoneClick,
    } = props;

    const { user } = useAuth0();
    const navigation = useNavigate();
    const dispatch = useAppDispatch();

    const initialValues: any = {
        username: "",
        password: "",
    };

    const validationSchema = Yup.object<any>({
        username: Yup.string().required("Please enter username"),
        password: Yup.string().required("Please enter password"),
    });

    const onSubmit = async (values: any) => {
        onGetUserInfo();
        try {
            let payload = { ...values };
            const res: any = await dispatch(userMigration(payload)).unwrap();
            if (res) {
                message.success("User Migration Successfully");
                onCancelClick();
            } else {
                message.error("User not migrated");
                onCancelClick();
            }
        } catch (error: any) {
            message.error("User not migrated");
            onCancelClick();
            console.log("user migration err -->", error?.message);
        }
    };

    const onGetUserInfo = async () => {
        try {
            const username = user?.email;
            if (username !== undefined) {
                const res: any = await dispatch(getUserInfo(username)).unwrap();
                // linkAccount();
                if (res.error === "invalid user") {
                    dispatch(setErrorPageData(res));
                    dispatch(setIsErrorPage(true));
                    navigation("/invalid-user");
                    dispatch(setLoading(false));
                }
                dispatch(setUserInfo(false));
                dispatch(setLoading(false));
            } else {
                dispatch(setUserInfo(false));
                dispatch(setLoading(false));
            }
        } catch (error: any) {
            dispatch(setIsErrorPage(true));
            navigation("/invalid-user");
            dispatch(setLoading(false));
            console.log("Get USer Info Err: --->", error?.message);
        }
    };

    const { values, touched, errors, handleBlur, handleChange, handleSubmit } =
        useFormik({
            initialValues,
            validationSchema,
            onSubmit,
        });

    return (
        <>
            <ConfirmationModal
                isLoading={isLoading}
                visible={visible}
                onCancelClick={onCancelClick}
                onDoneClick={onDoneClick}
                doneText="Submit"
                cancelText="Cancel"
                title="Migration Form"
                infoIcon={false}
                className="!top-[calc(50%-150px)]"
                form="migration"
                type="submit"
            >
                <form onSubmit={handleSubmit} id="migration">
                    <div className="grid grid-cols-2 gap-4 py-4 items-start smd:grid-cols-1">
                        <Input
                            label="Username"
                            placeholder="Enter username"
                            name="username"
                            value={values.username}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            errors={errors}
                            touched={touched}
                        />
                        <Input
                            label="Password"
                            placeholder="Enter password"
                            name="password"
                            value={values.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            errors={errors}
                            touched={touched}
                        />
                    </div>
                </form>
            </ConfirmationModal>
        </>
    );
};

export default MigrationFormModal;
