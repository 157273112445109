import { IconProp, SizeProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// PROJECT IMPORTS
import Select, { StylesConfig } from "react-select";

// TYPES
interface SelectProps {
    className?: string;
    isShowLabel?: boolean;
    maxMenuHeight?: number;
    label?: string;
    name: string;
    isShowPlaceholder?: boolean;
    placeholder?: string;
    icon?: IconProp;
    tooltip?: string;
    isInfo?: boolean;
    options: any[];
    value?: string | number;
    touched?: any;
    errors?: any;
    onChange?: any;
    onBlur?: any;
    onIconClick?: any;
    styles?: any;
    defaultValue?: any;
    isClearable?: boolean;
    height?: string;
    isManual?: boolean;
    isGroup?: boolean;
    isDisabled?: boolean;
    color?: string;
    size?: SizeProp;
}

/* ============================== SELECT ============================== */

const SelectInput = (props: SelectProps) => {
    const {
        className,
        isShowLabel = true,
        label,
        name,
        placeholder = "Select",
        icon,
        options,
        value,
        touched,
        errors,
        onChange,
        maxMenuHeight = 200,
        styles,
        defaultValue,
        isClearable = false,
        height,
        isManual = false,
        isGroup = false,
        isDisabled = false,
        color = "white",
        size = "xl",
    } = props;

    const customStyles: StylesConfig<any> = {
        control: (styles: any, state:any) => {
            return {
                ...styles,
                height: height ? height : "28px",
                minHeight: height ? height : "28px",
                borderColor: state.isFocused
                    ? "#38A6DE"
                    : touched && errors && touched[name] && errors[name]
                        ? "#E7515A"
                        : "#D8D8D8",
                borderWidth: "2px",
                borderRadius: "6px",
                boxShadow: "none",
                padding: "0px 3px",
                color: "#13151F",

                ":hover": {
                    borderColor: state.isFocused
                        ? "#38A6DE"
                        : touched && errors && touched[name] && errors[name]
                            ? "#E7515A"
                            : "#D8D8D8",
                },

                "@media (min-width: 1401px)": {
                    height: "36px",
                    minHeight: "36px",
                },
            };
        },
        placeholder: (styles: any) => {
            return {
                ...styles,
                color: "#B2B3B5",
                margin: 0,
            };
        },
        valueContainer: (styles: any) => {
            return {
                ...styles,
                padding: "0px 8px",
            };
        },
        dropdownIndicator: (styles: any) => {
            return {
                ...styles,
                padding: "2px 6px",
                color: "#D8D8D8",
            };
        },
        indicatorSeparator: (styles: any) => {
            return {
                ...styles,
                display: "none",
            };
        },
        option: (styles: any, state:any) => {
            return {
                ...styles,
                backgroundColor: state.isSelected
                    ? "#38A6DE"
                    : state.isFocused
                        ? "#e2f6ff"
                        : "white",
                fontWeight: state.isSelected ? 700 : 400,
                color: state.isSelected ? "white" : "#13151F",

                ":active": {
                    backgroundColor: state.isSelected
                        ? "#38A6DE"
                        : state.isFocused
                            ? "#e2f6ff"
                            : "white",
                },
            };
        },
        menuList: (styles: any) => {
            return {
                ...styles,
                borderColor: "#38A6DE",
                borderWidth: "2px",
                borderRadius: "6px",
            };
        },
    };

    // GET VALUES
    const getValues = () => {
        if (isGroup) {
            let optionArray: any = [];
            // eslint-disable-next-line array-callback-return
            options?.map((x: any) => {
                let data: any = x?.options || [];
                optionArray.push(...data);
            });
            return optionArray.find((x: any) => x.value === value);
        } else {
            return options.find((x: any) => x.value === value);
        }
    };

    const getDefaultValue = () =>
        options.find((x: any) => x.value === defaultValue);

    return (
        <div className={`${className} relative`}>
            {isShowLabel && (
                <div className="text-xs font-semibold pb-1.5 flex items-center">
                    <p>{label ? label : name}</p>
                </div>
            )}
            <div>
                {icon && (
                    <FontAwesomeIcon
                        className="cursor-pointer"
                        color={color}
                        icon={icon}
                        size={size}
                    />
                )}
                <Select
                    isDisabled={isDisabled}
                    className="w-full text-xs"
                    name={name}
                    value={getValues() || ""}
                    placeholder={placeholder}
                    defaultValue={getDefaultValue() || ""}
                    options={options}
                    styles={styles ? styles : customStyles}
                    onChange={(e: any) => {
                        if (isManual) {
                            onChange(e);
                        } else {
                            onChange({ target: { value: e ? e.value : "", id: name } });
                        }
                    }}
                    maxMenuHeight={maxMenuHeight}
                    isClearable={isClearable}
                />
            </div>
            {touched && errors && touched[name] && errors[name] && (
                <span className="text-error font-normal text-[10px]">
                    {errors[name]}
                </span>
            )}
        </div>
    );
};

export default SelectInput;
