import { baseUrl } from "API/baseURL";
import { message } from "antd";
import axios from "axios";

export const apiInstance = axios.create({
  baseURL: baseUrl,
});

apiInstance.interceptors.request.use(
  async function (config) {
    const accessToken = localStorage.getItem("accessToken");
    config.headers.Authorization = accessToken
      ? "Bearer " + accessToken
      : undefined;
    // config.headers.Authorization =
    //   "Bearer uA%Jk%6iAeC5QTgrgU4%PN6$Bk55jV94U!$CtY6wWYvAU2JrUqFxQNv4YbGHd$sh";
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// Add a response interceptor
apiInstance.interceptors.response.use(
  function (response) {
    return response.data;
  },
  function (error) {
    if (error.response.status === 400) {
      return error.response.data;
    } else if (typeof error.response.data.message === "string") {
      message.error(error.response.data.message);
    } else {
      for (let i = 0; i < error?.response?.data?.message?.length; i++) {
        message.error(error.response.data.message[i]);
      }
    }
    return Promise.reject(error);
  }
);
