// PROJECT IMPORTS
import DataTable from "react-data-table-component";
import Pagination from "./Pagination";

import nodata from "assets/images/no-data.svg";

import {
    modalTableCustomStyle,
    tableCustomStyle,
    tableCustomStyles,
} from "components/fax/mock";
import Loader from "../loader/Loader";

interface TableProps {
    columns: any[];
    data: any;
    pagination?: boolean;
    page?: number;
    totalCount?: number;
    limit?: number;
    onPageClick?: any;
    isLoading?: boolean;
    onLimitChange?: any;
    customHeight?: string;
    fixedHeaderScrollHeight?: string;
    conditionalRowStyles?: any

}

/* ============================== TABLE ============================== */

const Table = (props: TableProps) => {
    const {
        columns,
        data,
        pagination = true,
        page,
        totalCount,
        limit = 10,
        onPageClick,
        isLoading,
        onLimitChange,
        fixedHeaderScrollHeight,
        customHeight,
        conditionalRowStyles,
    } = props;

    const noDataComponent = () => {
        return (
            <>
                <div className="flex flex-col items-center gap-2">
                    <img src={nodata} alt="no data" height={100} width={100} />
                    <label className="text-base font-bold text-black">
                        No Data Found
                    </label>
                </div>
            </>
        );
    };

    return (
        <>
            {isLoading ? (
                <div
                    className={`bg-white rounded-lg ${!pagination
                            ? "h-[calc(100vh-228px)] smd:h-[calc(100vh-310px)]"
                            : "h-[calc(100vh-160px)] smd:h-[calc(100vh-192px)]"
                        } ${customHeight}`}
                >
                    <Loader background="transparent" />
                </div>
            ) : (
                <div
                    className={`relative bg-white rounded-lg ${!pagination
                            ? "h-[calc(100vh-228px)] smd:h-[calc(100vh-310px)]"
                            : "h-[calc(100vh-160px)] smd:h-[calc(100vh-192px)]"
                        } border border-[#EDEDED] ${customHeight}`}
                >
                    <DataTable
                        className={`!rounded-[unset] ${data?.length === 0 && "!border-none"
                            }`}
                        fixedHeader={true}
                        columns={columns}
                        data={data?.length ? data : []}
                        striped
                        customStyles={
                            data?.length > 0
                                ? tableCustomStyles
                                : customHeight
                                    ? modalTableCustomStyle
                                    : tableCustomStyle
                        }
                        fixedHeaderScrollHeight={
                            fixedHeaderScrollHeight
                                ? fixedHeaderScrollHeight
                                : "calc(100vh - 228px)"
                        }
                        defaultSortAsc={false}
                        noDataComponent={noDataComponent()}
                        conditionalRowStyles={conditionalRowStyles}
                        responsive
                    />
                    {pagination && data?.length > 0 ? (
                        <div className="w-full absolute bottom-0">
                            <Pagination
                                page={page}
                                limit={limit}
                                totalCount={totalCount}
                                onPageClick={onPageClick}
                                isAdvance={limit < data?.length}
                                count={data?.length}
                                onLimitChange={onLimitChange}
                            />
                        </div>
                    ) : null}
                </div>
            )}
        </>
    );
};

export default Table;
