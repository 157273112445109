import { useState } from "react";
import { DatePicker } from "antd";

// TYPES
interface DateRangePickerModalProps {
    visible: boolean;
    onCancelClick?: any;
    onDoneClick?: any;
}

const DateRangePickerModal = (props: DateRangePickerModalProps) => {
    const { RangePicker } = DatePicker;

    const { visible = false, onCancelClick, onDoneClick } = props;
    const [dateRange, changeDateRange] = useState<any>(null);
    const handleDateChange = (dates: any, dateString: any) => {
        console.log(dates, dateString);
        onDoneClick(dateString[0], dateString[1]);
        changeDateRange(null);
    };

    return (
        <>
            <div
                id="popup-modal"
                tabIndex={-1}
                className={`fixed top-0 left-0 right-0 z-[60] px-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[100%] max-h-full bg-[#18508D] bg-opacity-60 ${!visible && "hidden"
                    }`}
                onClick={onCancelClick}
            >
                <div className="w-full h-full relative flex justify-center">
                    <div className="absolute max-w-[330px] top-[80px] w-full max-h-full smd:left-0">
                        <div onClick={(e: any) => e.stopPropagation()}>
                            <div>
                                <RangePicker
                                    open={visible}
                                    allowClear={true}
                                    value={dateRange !== "" ? dateRange : ""}
                                    showTime
                                    onChange={handleDateChange}
                                    inputReadOnly
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DateRangePickerModal;
