import styled from "styled-components"
import OCLoginBg from "../../assets/images/oc-login_background.png"

interface TryAgainLoginWrapperProps {
  isSmallMobile: boolean
}

const TryAgainLoginWrapper = styled.div<TryAgainLoginWrapperProps>`
  background-image: ${(props) =>
    props.isSmallMobile ? "none" : `url(${OCLoginBg})`};
  min-height: 100vh;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: auto;
  background-color: ${(props) =>
    props.isSmallMobile ? "white" : "transparent"};

  .login-card-wrapper {
    min-height: 100vh;
    padding: 10px: 0px;
  }

  .login-card {
    max-width: 400px;
    width: 400px;
    background-color: white;
    padding: ${(props) => (props.isSmallMobile ? "100px 20px" : "100px 40px")};
    border-radius: 22px;

    .title {
      font-weight: 400;
      font-size: 24px;
    }

    .error {
      font-weight: 400;
      font-size: 14px;
      color: #d03c38;
      text-align: center;
    }

    .link-btn {
      font-weight: bold;
      font-size: 14px;
      color: #38a6de;

    }

    .try-again-btn {
      width: 100%;
      border-radius: 9999px;
      height: 38px;
      font-size: 14px;
    }
  }
`

export default TryAgainLoginWrapper
