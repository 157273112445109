/* eslint-disable array-callback-return */
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import {
    getUserInfo,
    resedFax,
    useNumberDetails,
    useSentListDetails,
    getSendingFaxList,
    getSendingfaxstatus
} from "redux/slice/faxSlice";
import { useAppDispatch } from "redux/hooks";
import {
    setCustomeRange,
    setDeleteHide,
    setErrorPageData,
    setIsCustomeRange,
    setIsErrorPage,
    setLoading,
    setSentType,
    setUserInfo,
    useCustomeRange,
    useHideDelete,
    useIsCustomeRange,
    useSentType,
    useStatusfilter,
    useNumber,
} from "redux/slice/commanSlice";
import { faRefresh, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, CheckBox, Input } from "components/ui-component/forms";
import {
    DownloadFile,
    // getFilenameFromContentDisposition,
} from "components/Helper/DownloadFile";
import Loader from "components/ui-component/loader/Loader";
import Table from "components/ui-component/Table/Table";

import download from "assets/icons/download.svg";
import send from "assets/icons/gray-icon/send.svg";
import edit from "assets/icons/gray-icon/edit.svg";
import calendar from "assets/icons/calendar.svg";
import nodata from "assets/images/no-data.svg";
import trash from "assets/icons/trash.svg";
import lock from "assets/icons/lock.svg";
// import searchIcon from "assets/icons/search.svg";

import { format } from "date-fns";
import Options from "./Options";
// import readmail from "assets/icons/read-mail.svg";
// import mail from "assets/icons/mail.svg";

// import read_eye from "assets/icons/read_eye.svg";
// import unread_eye from "assets/icons/unread_eye.svg";

import { message } from "antd";
import Pagination from "components/ui-component/Table/Pagination";
import { useAuth0 } from "@auth0/auth0-react";
// import add from "assets/icons/white-icon/add.svg";

interface SentFaxProps {
    filter: any;
    setFilter: any;
    setDeleteId: any;
    setSentFaxData: any;
    setIsWarn: any;
    isLoading: boolean;
    setOpenDate: any;
    setFaxLogs: any;
}

const SentFax = (props: SentFaxProps) => {
    const {
        setDeleteId,
        setSentFaxData,
        setFilter,
        filter,
        setIsWarn,
        isLoading,
        setOpenDate,
        // setFaxLogs,
    } = props;

    const { user } = useAuth0();
    const navigation = useNavigate();
    const sentFaxList = useSentListDetails();
    // const sendingFaxList = useSendingListDetails();
    const sentType = useSentType();
    const status_filter = useStatusfilter();
    const hide_deleted = useHideDelete();
    const customRange = useCustomeRange();
    const isCustomRange = useIsCustomeRange();
    const numberDetails = useNumberDetails();
    const number = useNumber();
    const [sendingFax, setSendingFax] = React.useState<any>(0);
    const [faxStatusList, setFaxStatusList] = React.useState<any[]>([]);
    const [faxStatusSendingList, setFaxStatusSendingList] = React.useState<any[]>([]);
    const [faxUuids, setFaxUuids] = React.useState<string[]>([]);

    const [open, setOpen] = React.useState<boolean>(false);
    const [search, setSearch] = React.useState<boolean>(
        filter.search_query === "" ? false : true
    );

    useEffect(() => {
        setFilter({
            ...filter,
            status_filter: status_filter,
            page: "1",
            per_page: "10",
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status_filter]);

    const List = [
        "All",
        "Today",
        "Yesterday",
        "Last 7 Days",
        "Last 30 Days",
        "Custom Range",
    ];

    const dispatch = useAppDispatch();

    const onDownloadFax = async (data: any, path: string) => {
        onGetUserInfo();
        const accessToken = localStorage.getItem("accessToken");
        try {
            const response = await fetch(path, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
            if (response.ok) {
                console.log("Coming");
                const contentDisposition = response.headers.get('content-disposition');
                console.log(contentDisposition);
                console.log(response.headers);
                const responseArrayBuffer = await response.arrayBuffer();
                // const filename = getFilenameFromContentDisposition(response.headers);
                // const filename = `to-${data?.to_number}-from-${data?.from_number
                //     }-${format(new Date(), "yyyy-MM-dd_hh:mm:ss")}.pdf"`;
                DownloadFile(responseArrayBuffer, data?.friendly_name);
            } else {
                message.error("Sorry, we couldn't download that document");
            }
        } catch (error) {
            console.error("Error:", error);
            message.error("Sorry, we couldn't download that document");
        }
    };

    const onResendFax = async (id: string) => {
        onGetUserInfo();
        try {
            const res: any = await dispatch(resedFax(id)).unwrap();
            if (res && res.status === "Resend Accepted") {
                message.success("Fax resend queued");
                setFilter({ ...filter });
            } else {
                message.error("Something went wrong");
            }
        } catch (error: any) {
            console.log("fax resend err: --->", error?.message);
        }
    };

    useEffect(() => {
        const interval = setInterval(() => {
            onSendingstatus();
        }, 5000); // 5 seconds
    
        // Clean up the interval on component unmount
        return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        console.log("FAX UUIDS");
        console.log(faxUuids);
        if (faxUuids.length > 0) {
            const interval = setInterval(() => {
                faxUuids.forEach(async (uuid) => {
                    try {
                        const faxStatus: any = await dispatch(getSendingfaxstatus(uuid)).unwrap();
                        console.log("Fax status for UUID", uuid, faxStatus);
    
                        const newStatusList = faxStatusList.filter(item => item.uuid !== uuid);
                        if (faxStatus.status !== "sending") {
                            if (faxStatus.status !== "Error") {
                                setFaxUuids(prevUuids => prevUuids.filter(prevUuid => prevUuid !== uuid));
                                setFaxStatusList([...newStatusList, { uuid, status: faxStatus.status, pages: faxStatus.pages_sent }]);
                            } else {
                                setFaxStatusList([...newStatusList, { uuid, status: faxStatus.status, pages: faxStatus.pages_sent }]);
                            }
                        } else {
                            setFaxStatusList([...newStatusList, { uuid, status: faxStatus.status, pages: faxStatus.pages_sent }]);
                        }
                    } catch (error: any) {
                        console.log("Error fetching status for UUID", uuid, ":", error?.message);
                    }
                });
            }, 5000); // 5 seconds
    
            return () => clearInterval(interval);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [faxUuids]);


    const onSendingstatus = async () => {
        console.log("API CALL HAPPEN FROM SENDING FAX HERE");
        try {
            let params = {
                ...filter,
                username: user?.email,
                phone_number: number,
                direction: "out",
                status_filter: "sending"
            };
            const sendingfax: any = await dispatch(getSendingFaxList(params)).unwrap();
            console.log("Sending fax");
            console.log(sendingfax);
            console.log("SENDING FAX DATA");
            console.log(sendingfax.total_faxes);
    
            if (sendingfax && sendingfax.total_faxes > 0) {
                setSendingFax(sendingfax.total_faxes);
                sendingfax.faxes.forEach(async (fax: any) => {
                    if (!faxUuids.includes(fax.uuid)) {
                        console.log("COMING HERE 1");
                        const newStatusList = faxStatusList.filter(item => item.uuid !== fax.uuid);
                        setFaxStatusSendingList([...newStatusList, { uuid: fax.uuid, pages: fax.pages }]);
                        setFaxUuids(prevUuids => {
                            if (!prevUuids.includes(fax.uuid)) {
                                return [...prevUuids, fax.uuid];
                            }
                            return prevUuids;
                        })
                    }
                });
            } else {
                setSendingFax(0);
                setFaxUuids(prevUuids => {
                    // if (!prevUuids.includes(fax.uuid)) {
                    //     return [...prevUuids, fax.uuid];
                    // }
                    return prevUuids;
                })
               
            }
        } catch (error: any) {
            console.log("fax list get error -->", error?.message);
        }
    }

    React.useEffect(() => {
        onSetSearchDate();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sentType]);

    const onGetUserInfo = async () => {
        try {
            const username = user?.email;
            if (username !== undefined) {
                const res: any = await dispatch(getUserInfo(username)).unwrap();
                // linkAccount();
                if (res.error === "invalid user") {
                    dispatch(setErrorPageData(res));
                    dispatch(setIsErrorPage(true));
                    navigation("/invalid-user");
                    dispatch(setLoading(false));
                }
                dispatch(setUserInfo(false));
                dispatch(setLoading(false));
            } else {
                dispatch(setUserInfo(false));
                dispatch(setLoading(false));
            }
        } catch (error: any) {
            dispatch(setIsErrorPage(true));
            navigation("/invalid-user");
            dispatch(setLoading(false));
            console.log("Get USer Info Err: --->", error?.message);
        }
    };

    const onSetSearchDate = () => {
        switch (sentType) {
            case "All":
                setFilter({
                    ...filter,
                    page: "1",
                    per_page: "10",
                    start_date: undefined,
                    end_date: undefined,
                });
                break;
            case "Today":
                setFilter({
                    ...filter,
                    page: "1",
                    per_page: "10",
                    start_date: format(
                        new Date().setHours(0, 0, 1),
                        "yyyy-MM-dd HH:mm:ss"
                    ),
                    end_date: format(
                        new Date().setHours(23, 59, 59),
                        "yyyy-MM-dd HH:mm:ss"
                    ),
                });
                break;
            case "Yesterday":
                setFilter({
                    ...filter,
                    page: "1",
                    per_page: "10",
                    start_date: format(
                        new Date(Date.now() - 1 * 24 * 60 * 60 * 1000).setHours(0, 0, 1),
                        "yyyy-MM-dd HH:mm:ss"
                    ),
                    end_date: format(
                        new Date(Date.now() - 1 * 24 * 60 * 60 * 1000).setHours(23, 59, 59),
                        "yyyy-MM-dd HH:mm:ss"
                    ),
                });
                break;
            case "Last 7 Days":
                setFilter({
                    ...filter,
                    page: "1",
                    per_page: "10",
                    start_date: format(
                        new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).setHours(0, 0, 1),
                        "yyyy-MM-dd HH:mm:ss"
                    ),
                    end_date: format(
                        new Date().setHours(23, 59, 59),
                        "yyyy-MM-dd HH:mm:ss"
                    ),
                });
                break;
            case "Last 30 Days":
                setFilter({
                    ...filter,
                    page: "1",
                    per_page: "10",
                    start_date: format(
                        new Date(Date.now() - 30 * 24 * 60 * 60 * 1000).setHours(0, 0, 1),
                        "yyyy-MM-dd HH:mm:ss"
                    ),
                    end_date: format(
                        new Date().setHours(23, 59, 59),
                        "yyyy-MM-dd HH:mm:ss"
                    ),
                });
                break;

            case "Custom Range":
                if (isCustomRange) {
                    setFilter({
                        ...filter,
                        page: "1",
                        per_page: "10",
                        start_date: format(
                            new Date(customRange?.start_date).setHours(0, 0, 1),
                            "yyyy-MM-dd HH:mm:ss"
                        ),
                        end_date: format(
                            new Date(customRange?.end_date).setHours(23, 59, 59),
                            "yyyy-MM-dd HH:mm:ss"
                        ),
                    });
                }
                break;

            default:
                break;
        }
    };

    const columns = [
        // {
        //     name: "",
        //     selector: (row: any) => row.notes,
        //     width: "150px",
        //     cell: (row: any, index: number) => {
        //       return (
        //         <div className="flex gap-2 items-center">
        //           {row?.download_link ? (
        //             <button
        //               className="bg-primary rounded-3xl text-white px-2 py-1 text-xs inline-flex items-center justify-center"
        //               onClick={() => onDownloadFax(row, row?.download_link)}
        //             >
        //               <img src={download} alt="download" width="10px" height="10px" className="w-4 h-4 mr-1" style={{ filter: 'invert(1)' }}/>
        //               <span> Download </span>
        //             </button>
        //           ) : null}
        //         </div>
        //       );
        //     },
        //   },
        {
            name: "From Number",
            selector: (row: any) => row.from_number,
            cell: (row: any) => (
                <div className="flex gap-3 items-center">
                    {/* <img
              className="cursor-pointer"
              src={row?.viewed === "yes" ? read_eye : unread_eye}
              alt="unread"
              title={row?.viewed === "yes" ? "Read" : "Unread"}
            /> */}
                    <label>{row.from_number}</label>
                    <img
                        className="cursor-pointer"
                        src={lock}
                        alt="lock"
                        title="To ensure the utmost security and confidentiality, all faxes are individually encrypted using a Key Management Service"
                    />
                </div>
            ),
            sortable: true,
            //width: "170px",
            width: "15%",
        },
        {
            name: "To Number",
            selector: (row: any) => row.to_number,
            sortable: true,
            //width: "120px",
            width: "12%",
        },
        {
            name: "Date",
            selector: (row: any) => row.created_on,
            sortable: true,
            //width: "180px",
            width: "18%",
        },
        {
            name: "Status",
            cell: (row: any) => {
                const faxStatus = faxStatusList.find(item => item.uuid === row.uuid);
                const status = faxStatus ? faxStatus.status : row.status;
                const pages = faxStatus ? faxStatus.pages : null;

                return (
                    <div className="flex gap-2 items-center">
                        <div
                            className={`${status === "sending"
                                    ? "bg-primary"
                                    : status === "delivered" || status === "complete"
                                        ? "bg-primary-green"
                                        : status === "deleted"
                                            ? "bg-layout"
                                            : "bg-[#FF455C]"
                                } rounded-3xl text-white px-2 py-1 text-xs`}
                        >
                            <label className="capitalize">{status} {status === "sending" && pages !== null && `(${pages})`}</label>
                        </div>
                        {status === "failed" && (
                            <FontAwesomeIcon
                                className="cursor-pointer"
                                color="#38A6DE"
                                icon={faRefresh}
                                title="Retry"
                                onClick={() => {
                                    onResendFax(row.uuid);
                                }}
                            />
                        )}
                    </div>
                );
            },
            selector: (row: any) => row.viewed,
            sortable: true,
           // width:"150px"
           width: "13%",
        },
        {
            name: "Pages",
            cell: (row: any) => {
                const faxStatus = faxStatusSendingList.find(item => item.uuid === row.uuid);
                const pages = faxStatus ? faxStatus.pages : row.pages;
        
                return (
                    <div>{pages}</div>
                );
            },
            selector: (row: any) => row.pages,
            sortable: true,
            //width: "100px",
            width: "10%",
        },
        {
            name: "Notes",
            selector: (row: any) => row.notes,
            sortable: true,
            //width:"200px",
            width: "10%",
            cell: (row: any) => (
                <div className="overflow-hidden max-w-full">
                    <span
                        title={row.notes}
                        className="whitespace-normal break-words overflow-hidden"
                        style={{ display: "-webkit-box", WebkitBoxOrient: "vertical", WebkitLineClamp: 2 }}
                    >
                        {row.notes}
                    </span>
                </div>
            ),
             
        },
        // {
        //     name: "Download",
        //     selector: (row: any) => row.notes,
        //     width: "145px",
        //     cell: (row: any, index: number) => {
        //       return (
        //         <div className="flex gap-2 items-center">
        //           {row?.download_link ? (
        //             <button
        //               className="bg-primary rounded-3xl text-white px-2 py-1 text-xs"
        //               onClick={() => onDownloadFax(row, row?.download_link)}
        //             >
        //               Download Fax
        //             </button>
        //           ) : null}
        //         </div>
        //       );
        //     },
        //   },
        {
            name: "",
            right: "true",
            //width:"100px",
            width: "23%",
            cell: (row: any) => {
                return (
                    <div className="flex gap-3 items-center justify-end text-end">
                        {/* {row?.fax_log && row?.fax_log?.length ? (
                            <div
                                className="cursor-pointer"
                                onClick={() => {
                                    setFaxLogs(row?.fax_log);
                                }}
                            >
                                <img src={searchIcon} alt="search" height={15} width={15} />
                            </div>
                        ) : null} */}
                        {/* {row?.download_link ? (
                            <div
                                className="cursor-pointer"
                                onClick={() => onDownloadFax(row, row?.download_link)}
                            >
                                <img src={download} alt="download" width="17px" height="17px" />
                            </div>
                        ) : null} */}
                        {row?.download_link ? (
                    <button
                      className="bg-primary rounded-3xl text-white px-2 py-1 text-xs inline-flex items-center justify-center "
                      onClick={() => onDownloadFax(row, row?.download_link)}
                      title="Download Fax"
                      style={{ fontSize: 'calc(0.6rem + 0.2vw)' }}
                     
                    >
                      <img src={download} alt="download" width="10px" height="10px" className="w-4 h-4 mr-1" style={{ filter: 'invert(1)' }}/>
                      <span style={{ whiteSpace: 'nowrap' }}> Download </span>
                    </button>
                  ) : null}
                        
                        <div className="cursor-pointer" onClick={() => setSentFaxData(row)} title="Edit">
                            <img src={edit} alt="edit"  width="17px" height="17px"/>
                        </div>
                        <div
                            className="cursor-pointer"
                            onClick={() => onResendFax(row?.uuid)}
                            title="Re-Send"
                        >
                            <img src={send} alt="send" width="16px" height="16px" />
                        </div>
                        {row?.status !== "deleted" && (
                            <div
                                className="cursor-pointer"
                                onClick={() => setDeleteId(row?.uuid)}
                                title="Delete"
                            >
                                <img src={trash} alt="trash" width="16px" height="16px" />
                            </div>
                        )}
                    </div>
                );
            },
        },
        // {
        //     name: "",
        //     selector: (row: any) => row.notes,
        //     width: "145px",
        //     cell: (row: any, index: number) => {
        //       return (
        //         <div className="flex gap-2 items-center">
        //           {row?.download_link ? (
        //             <button
        //               className="bg-primary rounded-3xl text-white px-2 py-1 text-xs"
        //               onClick={() => onDownloadFax(row, row?.download_link)}
        //             >
        //               Download Fax
        //             </button>
        //           ) : null}
        //         </div>
        //       );
        //     },
        //   },
//         {
//             name: "",
//             right: "true",
//             selector: (row: any) => row.notes,
//             //width:"100px",
//             cell: (row: any, index: number) => {
//               return (
//                 // <div className="flex gap-3 items-center">
      
//                 //   {row?.download_link ? (
//                 //     <div
//                 //       className="cursor-pointer"
//                 //       onClick={() => onDownloadFax(row, row?.download_link)}
//                 //     >
//                 //       <img src={download} alt="download" width="17px" height="17px"/>
//                 //     </div>
//                 //   ) : null}
      
//                 // </div>

// <div className="flex gap-2 justify-end items-center ">

// <Button text="Download Fax" onClick={() => onDownloadFax(row, row?.download_link)} className="!py-2" />
// </div>
//               );
//             },
//           },
    ];

    return (
        <>
            <div onClick={() => setOpen(false)}>
                <div className="flex justify-between pb-2 px-1 smd:gap-y-3 smd:px-2 smd:shadow-lg smd:bg-white">
                    <div className="flex gap-4 items-center smd:flex-wrap">
                        <div className="flex items-center gap-2">
                            {sentFaxList?.total_faxes > 0 ? (
                                <label className="text-sm font-bold text-heading">{`Sent Faxes (${sentFaxList?.total_faxes || 0
                                    })`}</label>
                            ) : (
                                <label className="text-sm font-bold text-heading">
                                    Sent Faxes
                                </label>
                            )}
                            <FontAwesomeIcon
                                className="cursor-pointer"
                                color="#38a6de"
                                icon={faRefresh}
                                onClick={() => setFilter({ ...filter })}
                            />
                        </div>
                        <div
                            className="relative smd:hidden"
                            onClick={(e: any) => e.stopPropagation()}
                        >
                            <div
                                className="flex gap-2 items-center bg-[#ECEEF5] rounded-2xl px-2 py-1"
                                onClick={() => setOpen(!open)}
                            >
                                <img src={calendar} alt="calendar" />
                                <label className="text-xs font-semibold text-black">
                                    {sentType === "Custom Range"
                                        ? customRange?.start_date + " - " + customRange?.end_date
                                        : sentType}
                                </label>
                            </div>
                            <div
                                className={`absolute top-8 left-0 bg-white rounded-md drop-shadow-md py-1 w-[120px] z-[2] flex flex-col ${open ? "block" : "hidden"
                                    }`}
                            >
                                {List?.map((val: any, index: number) => {
                                    return (
                                        <div
                                            key={index}
                                            className={`flex items-center gap-3 py-1 px-3 cursor-pointer ${val === "Custom Range"
                                                    ? "!text-primary"
                                                    : "border-b border-dark-800 text-black"
                                                }`}
                                            onClick={() => {
                                                setOpen(false);
                                                val === "Custom Range" &&
                                                    dispatch(
                                                        setCustomeRange({
                                                            start_date: format(
                                                                new Date().setHours(0, 0, 1),
                                                                "yyyy-MM-dd HH:mm:ss"
                                                            ),
                                                            end_date: format(
                                                                new Date().setHours(23, 59, 59),
                                                                "yyyy-MM-dd HH:mm:ss"
                                                            ),
                                                        })
                                                    );
                                                dispatch(setSentType(val));
                                                val === "Custom Range" && setOpenDate(true);
                                                val !== "Custom Range" &&
                                                    dispatch(setIsCustomeRange(false));
                                                onSetSearchDate();
                                            }}
                                        >
                                            <span className="text-xs font-semibold">{val}</span>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <div className="w-[120px] smd:hidden">
                            <Options />
                        </div>
                        <div className="smd:hidden">
                            <CheckBox
                                label="Show Deleted Faxes"
                                value={hide_deleted}
                                checked={hide_deleted === "no" ? true : false}
                                onChange={() => {
                                    if (hide_deleted === "yes") {
                                        setFilter({
                                            ...filter,
                                            page: "1",
                                            per_page: "10",
                                            hide_deleted: "no",
                                        });
                                    } else {
                                        setFilter({
                                            ...filter,
                                            page: "1",
                                            per_page: "10",
                                            hide_deleted: "yes",
                                        });
                                    }
                                    dispatch(setDeleteHide(hide_deleted === "no" ? "yes" : "no"));
                                }}
                            />
                        </div>
                        {sendingFax > 0  && (
                        <div className="flex items-center gap-2">
                                <label className="text-sm font-bold text-heading">{`Sending Faxes (${sendingFax
                                    })`}</label>
                           
                            <FontAwesomeIcon
                                className="cursor-pointer"
                                color="#38a6de"
                                icon={faRefresh}
                                //onClick={() => setFilter({ ...filter })}
                            />
                        </div>
                        )}
                    </div>
                    <div className="flex items-center gap-2 smd:justify-end">
                        <div className="border-dark-700 border px-1.5 py-0.5 rounded-md cursor-pointer flex items-center">
                            <div
                                className={`${search ? "border-r border-dark-700 pr-1.5" : ""}`}
                            >
                                <FontAwesomeIcon
                                    className="cursor-pointer"
                                    color="#8083A3"
                                    icon={faSearch}
                                    onClick={() => {
                                        if (search) {
                                            setFilter({
                                                ...filter,
                                                search_query: "",
                                            });
                                        }
                                        setSearch(!search);
                                    }}
                                />
                            </div>
                            <Input
                                name="search"
                                isShowLabel={false}
                                value={filter.search_query}
                                placeholder="Search by name, phone and extension number"
                                className={`${!search && "hidden"
                                    } transition ease-in-out delay-1000 resize-none rounded-md w-[275px] !py-[5px] focus:outline-none !border-none`}
                                onChange={(e: any) => {
                                    setFilter({
                                        ...filter,
                                        search_query: e.target.value,
                                    });
                                }}
                            />
                        </div>
                        <Button
                            className="smd:hidden"
                            text="Compose a fax"
                            icon="add-white"
                            onClick={() => {
                                //if (
                                //    numberDetails?.total_pages >=
                                //    numberDetails?.license_page_limit
                                //) {
                                //    setIsWarn(true);
                                //} else {
                                    navigation("/fax/compose-fax");
                                //}
                            }}
                        />
                    </div>
                </div>
                <div className="hidden smd:block h-[calc(100vh-152px)] bg-[#F7FAFD] overflow-y-auto pb-[55px]">
                    {isLoading ? (
                        <Loader />
                    ) : sentFaxList?.faxes?.length ? (
                        <div className="">
                            {sentFaxList?.faxes.map((val: any, index: number) => {
                                return (
                                    <div className="p-2" key={index}>
                                        <div className="bg-white p-4 rounded-lg drop-shadow-sm">
                                            <div className="grid grid-cols-2 items-center">
                                                <div className="flex gap-3 items-center">
                                                    <label className="text-sm">To: {val.to_number}</label>
                                                </div>
                                                <div className="flex gap-1.5 items-center justify-end">
                                                    <img
                                                        width="15px"
                                                        height="15px"
                                                        className="cursor-pointer"
                                                        src={lock}
                                                        alt="lock"
                                                        title="To ensure the utmost security and confidentiality, all faxes are individually encrypted using a Key Management Service"
                                                    />
                                                    <div
                                                        className={`${val?.status === "sending"
                                                                ? "bg-primary"
                                                                : val?.status === "delivered" ||
                                                                    val?.status === "complete"
                                                                    ? "bg-primary-green"
                                                                    : val?.status === "deleted"
                                                                        ? "bg-layout"
                                                                        : "bg-[#FF455C]"
                                                            } rounded-full h-3 w-3`}
                                                    ></div>
                                                    <div
                                                        className={`${val?.status === "sending"
                                                                ? "text-primary"
                                                                : val?.status === "delivered" ||
                                                                    val?.status === "complete"
                                                                    ? "text-primary-green"
                                                                    : val?.status === "deleted"
                                                                        ? "text-layout"
                                                                        : "text-[#FF455C]"
                                                            } text-sm capitalize`}
                                                    >
                                                        {val?.status}
                                                    </div>
                                                    {val?.status === "failed" && (
                                                        <FontAwesomeIcon
                                                            className="cursor-pointer"
                                                            color="#38A6DE"
                                                            icon={faRefresh}
                                                            title="Retry"
                                                            onClick={() => {
                                                                onResendFax(val?.uuid);
                                                            }}
                                                            size="sm"
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                            <div className="grid grid-cols-2 items-center pt-2">
                                                <label className="text-sm">
                                                    From: {val.from_number || ""}
                                                </label>
                                                <label className="text-sm flex justify-end">
                                                    {val.created_on || ""}
                                                </label>
                                            </div>
                                            <div className="pt-2">
                                                <label className="text-sm font-semibold">
                                                    {val.notes || ""}
                                                </label>
                                            </div>
                                            <div className="pt-2 flex items-center justify-between">
                                                {/* {val?.fax_log && val?.fax_log?.length ? (
                                                    <div
                                                        className="flex items-center gap-2 cursor-pointer"
                                                        onClick={() => {
                                                            setFaxLogs(val?.fax_log);
                                                        }}
                                                    >
                                                        <img
                                                            src={searchIcon}
                                                            alt="search"
                                                            height={15}
                                                            width={15}
                                                        />
                                                        <span className="text-[13px] pt-0.5 text-black">
                                                            Fax Log
                                                        </span>
                                                    </div>
                                                ) : null} */}
                                                {val?.download_link ? (
                                                    <div
                                                        className="flex items-center gap-2 cursor-pointer"
                                                        onClick={() => {
                                                            onDownloadFax(val, val?.download_link);
                                                        }}
                                                    >
                                                        <img src={download} alt="download" />
                                                        <span className="text-[13px] pt-0.5 text-black">
                                                            Download
                                                        </span>
                                                    </div>
                                                ) : null}
                                                <div
                                                    className="flex items-center gap-2 cursor-pointer"
                                                    onClick={() => {
                                                        setSentFaxData(val);
                                                    }}
                                                >
                                                    <img src={edit} alt="edit" />
                                                    <span className="text-[13px] pt-0.5 text-black">
                                                        Edit
                                                    </span>
                                                </div>
                                                <div
                                                    className="flex items-center gap-2 cursor-pointer"
                                                    onClick={() => {
                                                        onResendFax(val?.uuid);
                                                    }}
                                                >
                                                    <img src={send} alt="send" />
                                                    <span className="text-[13px] pt-0.5 text-black">
                                                        Resend
                                                    </span>
                                                </div>
                                                {val?.status !== "deleted" && (
                                                    <div
                                                        className="flex items-center gap-2 cursor-pointer"
                                                        onClick={() => {
                                                            setDeleteId(val?.uuid);
                                                        }}
                                                    >
                                                        <img
                                                            src={trash}
                                                            alt="trash"
                                                            width="14px"
                                                            height="14px"
                                                        />
                                                        <span className="text-[13px] pt-0.5 text-error">
                                                            Delete
                                                        </span>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                            <div className="absolute bottom-0 w-full">
                                <Pagination
                                    count={sentFaxList?.faxes?.length || 0}
                                    totalCount={sentFaxList?.total_faxes || 0}
                                    page={filter?.page}
                                    limit={filter?.per_page}
                                    onPageClick={(page: number) => {
                                        setFilter({
                                            ...filter,
                                            page,
                                        });
                                    }}
                                    onLimitChange={(limit: number) => {
                                        setFilter({
                                            ...filter,
                                            per_page: limit,
                                            page: 1,
                                        });
                                    }}
                                    firstPageClick={() => {
                                        setFilter({
                                            ...filter,
                                            page: 1,
                                        });
                                    }}
                                    lastPageClick={() => {
                                        setFilter({
                                            ...filter,
                                            page: Math.ceil(
                                                sentFaxList?.total_faxes / filter.per_page
                                            ),
                                        });
                                    }}
                                />
                            </div>
                        </div>
                    ) : (
                        <div className="flex flex-col items-center justify-center gap-2 h-full">
                            <img src={nodata} alt="no data" height={100} width={100} />
                            <label className="text-base font-bold text-black">
                                No Faxes Found
                            </label>
                        </div>
                    )}
                </div>
                <div className="smd:hidden">
                    <Table
                        isLoading={isLoading}
                        columns={columns}
                        data={sentFaxList?.faxes?.length ? sentFaxList?.faxes : []}
                        totalCount={sentFaxList?.total_faxes || 0}
                        page={filter?.page}
                        limit={filter?.per_page}
                        onPageClick={(page: number) => {
                            setFilter({
                                ...filter,
                                page,
                            });
                        }}
                        onLimitChange={(limit: number) => {
                            setFilter({
                                ...filter,
                                per_page: limit,
                                page: 1,
                            });
                        }}
                        // conditionalRowStyles={[{
                        //     when: (row: any) => row?.viewed === 'no',
                        //     style: (row: any) => ({ backgroundColor: row?.viewed !== 'no' ? 'inherit' : '#C1ECFF',
                        //     color: row?.viewed !== 'no' ? '': 'black' }),
                        //   }]}
                    />
                </div>
            </div>
        </>
    );
};

export default SentFax;
