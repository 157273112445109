import styled from "styled-components"

const CreateEditContactForm = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 0.5rem;
  align-items: center;
  row-gap: 0.5rem;
  max-height: calc(100vh - 185px);
  overflow-y: auto;

  .text-label {
    font-size: 13px;
    font-weight: 600;
  }

  .details {
    grid-column: span 2 / span 2;

    .ant-input {
      padding: 6.5px 8px;
    }

    .ant-select-single {
      height: 36px;
    }

    .ant-form-item {
      margin: 0;
    }
  }

  .details-range {
    .ant-input {
      padding: 0 !important;
    }
  }

  .extra-add {
    display: flex;
    align-items: center;
    gap: 15px;

    .ant-btn {
      width: 25% !important;
    }
  }

  .list {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    align-items: center;
    padding-top: 2px;
    padding-bottom: 2px;
    p {
      font-size: 14px;
      line-height: 16px;
      grid-column: span 2 / span 2;
      max-width: 98%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
`
export default CreateEditContactForm
