/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable react/no-array-index-key */
/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from "react"
import { Col, Modal, Row, message } from "antd"
import * as Yup from "yup"
import { useFormik } from "formik"
import { Button } from "components/ui-component/forms";
import CreateEditContactForm from "./CreateEditContactForm.style"

import InputField from "../InputField/InputField"
import { useAppDispatch } from "redux/hooks";
import { createContact } from "redux/slice/faxSlice";
import { editContact } from "redux/slice/faxSlice";
import { useAuth0 } from "@auth0/auth0-react";





interface EditContactModalProps {
  isEdit: boolean
  data: any
  isOpen: boolean
  isLoading?: boolean
  closeModal: () => void
  onYes: () => void
  wrapClassName?: string
}

const EditContactModal: React.FC<EditContactModalProps> = ( {
  isOpen,
  isLoading,
  closeModal,
  onYes,
  isEdit,
  data,
  wrapClassName
} ) => {
  // const [ showConfirmationModal, setShowConfirmationModal ] = useState( false );
  // const [ directory, setDirectory ] = useState( false );
  // const [ process, setProcess ] = useState( false );
  // const [ editID, setEditID ] = useState();
  const dispatch = useAppDispatch();
  const { user } = useAuth0();
  const initialValues: any = {
    firstname: "",
    lastname: "",
    fax_number: "",
    notes:""
  }

  const validationSchema = Yup.object<any>( {
    //firstname: Yup.string().required( "Please enter first name" ),
    //lastname: Yup.string().required( "Please enter last name" ),
    fax_number: Yup.string().required( "Please enter fax number" ),
    //notes: Yup.string().required( "Please enter notes" )
  } )

  const onSubmit = async ( values: any ) => {
    let payload = {
      ...values
    }
    console.log("coming here");
    console.log(payload);
    if ( isEdit ) {
      onUpdateCustomer( payload )
    } else {
      onCreateCustomer( payload )
    }
    
  }

  const onUpdateCustomer = async ( values: any ) => {
    try {
      const username = user?.email;
      let payload = { ...values,
        username:username
         }
      
      console.log("payload");
      console.log(payload);
      //return true;
      const res: any = await dispatch( editContact( payload ) ).unwrap()
      // if ( res ) {
      //   onYes()
      //   resetForm()
      //   message.success( "Contact edited successfully" )
      // }
      if (res && res.status === "Error") {
        console.log("Error");
        console.log(res);
        console.log(res.status);
        onYes()
        resetForm()
        message.error("Something went wrong");
      } else {
        console.log("Success");
        console.log(res);
        console.log(res.status);
        onYes()
        resetForm()
        message.success( "Contact edited successfully" )
          
      }
    } catch ( error: any ) {
      console.log( "edit sent fax err: --->", error?.message )
    }
  }

  const onCreateCustomer = async ( values: any ) => {
    try {
      const payload = { ...values }
      console.log( "DATA" );
      console.log( payload );
      const res: any = await dispatch( createContact( payload ) ).unwrap()
      if ( res ) {
        onYes()
        resetForm()
        message.success( "Contact created successfully" )
      }
    } catch ( error: any ) {
      console.log( "edit sent fax err: --->", error?.message )
    }
  }

  const handleCancel = () => {
    closeModal();
    resetForm();
  };
  
  const handleChange = ( key: string, e: any ) => {

    if ( key === "fax_number" ) {
      // const regexNumber = /^[0-9]*$/;
      // if ( !regexNumber.test( e ) ) {
      //   message.error( "Only [0-9] are allowed." );
      //   return;
      // }
      // const regexNumber = /^(?:[2-9][0-9]*)?$/; // Allow empty string or digits 2-9 for the first character followed by any digits
      // if (!regexNumber.test(e)) {
      //   message.error("Only digits 2-9 are allowed.");
      //   return;
      // }
      const firstDigit = e.charAt(0);
      if (firstDigit === "0" || firstDigit === "1") {
        message.error("Fax number cannot start with 0 or 1.");
        return;
      }
      const regexNumber = /^[0-9]*$/; // Allow only digits 0-9 for the remaining characters
      if (!regexNumber.test(e)) {
        message.error("Only digits 0-9 are allowed.");
        return;
      }
      if ( e.length > 10 ) {
        message.error( "Maximum 10 digits are allowed." );
        return;
      }
    }

    setValues( {
      ...values,
      [ key ]: e
    } )
  }

  const {
    values,
    touched,
    errors,
    handleBlur,
    //setFieldValue,
    //handleChange,
    handleSubmit,
    resetForm,
    setValues
  } = useFormik( {
    initialValues,
    validationSchema,
    onSubmit
  } )

  useEffect( () => {
    if ( isEdit ) {
      console.log( "COMING FOR EDIT" );
      console.log(data);
      // setProcess( false );
      // setEditID( data?.contact_id );
      setValues( {
        ...values,
        id: data?.contact_id ? data?.contact_id : "",
        firstname: data?.firstname ? data?.firstname : "",
        lastname: data?.lastname ? data?.lastname : "",
        notes: data?.notes ? data?.notes : "",
        fax_number: data?.fax_number ? data?.fax_number : "",
      } )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ isEdit, data ] )

  return (
    <Modal
      title={[
        <div className="download-title delete-webinar-title">
          <span>{isEdit ? "Edit Contact" : "Create Contact"}</span>
        </div>
      ]}
      centered
      open={isOpen}
      wrapClassName={`registration-modal ${wrapClassName || ""}`}
      onCancel={() => handleCancel()}
      width={530}
      footer={[
        <Row>
          <Col xs={24} lg={12} />
          <Col xs={24} lg={12}>
          <div className="pt-4 border-t-2 border-dark-800 ">
                <div className="flex gap-2 justify-end items-center">
              
              <Button
                  text="Cancel"
                  styles="secondary-outline"
                  onClick={() => {
                    closeModal()
                    resetForm()
                  }}
                />
              
              <Button
                className="smd:hidden"
                text="Submit"
                // icon="add-white"
                type="submit"
                form="account-form"
                />
                 </div>
            </div>

          </Col>
        </Row>
      ]}
    >

     
      <form onSubmit={handleSubmit} id="account-form">
        <CreateEditContactForm style={{ overflowY: "hidden" }}>
          <div className="text-label">
            <p>First Name</p>
          </div>
          <div className="details">
            <InputField
              placeholder="Enter Your First Name"
              name="firstname"
              value={values?.firstname}
              onChange={( e: any ) => handleChange( "firstname", e.target.value )}
              onBlur={handleBlur}
              errors={errors}
              touched={touched}
            />
          </div>
          <div className="text-label">
            <p>Last Name</p>
          </div>
          <div className="details">
            <InputField
              placeholder="Enter Your Last Name"
              name="lastname"
              value={values?.lastname}
              onChange={( e: any ) => handleChange( "lastname", e.target.value )}
              onBlur={handleBlur}
              errors={errors}
              touched={touched}
            />
          </div>
          <div className="text-label">
            <p>Fax Number</p>
          </div>
          <div className="details">
            <InputField
              placeholder="Enter Your Fax Number"
              name="fax_number"
              type="tel"
              value={values?.fax_number}
              maxLength={10}
              onChange={( e: any ) => handleChange( "fax_number", e.target.value )}
              onBlur={handleBlur}
              errors={errors}
              touched={touched}
            />
          </div>
          <div className="text-label">
            <p>Note</p>
          </div>
          <div className="details">
            <InputField
              placeholder="Enter Your Note"
              name="notes"
              value={values?.notes}
              onChange={( e: any ) => handleChange( "notes", e.target.value )}
              onBlur={handleBlur}
              errors={errors}
              touched={touched}
            />
          </div>
          
          
          
        </CreateEditContactForm>
      </form>
    </Modal>
  )
}

export default EditContactModal
