import React from "react"
import { Col, Modal, Row } from "antd"
import InfoCircleIcon from "../Icons/InfoCircle"
import { Button } from "components/ui-component/forms";

interface ConfirmModalProps {
  isOpen: boolean
  isLoading?: boolean
  closeModal: () => void
  onYes: () => void
  description?: string
  descriptionClassName?: string
  title?: string
  wrapClassName?: string
  showDescriptionInFooter?: boolean
}

const ConfirmModal: React.FC<ConfirmModalProps> = ({
  isOpen,
  isLoading,
  closeModal,
  onYes,
  description,
  descriptionClassName,
  title,
  wrapClassName,
  showDescriptionInFooter = true
}) => {
  return (
    <Modal
      title={[
        <div className="download-title delete-webinar-title">
          <InfoCircleIcon /> {showDescriptionInFooter && <span>{title}</span>}
          {!showDescriptionInFooter && (
            <span className={descriptionClassName || ""}>
              {description} {title && <span>{title}</span>}
            </span>
          )}
        </div>
      ]}
      centered
      open={isOpen}
      wrapClassName={`registration-modal ${wrapClassName || ""}`}
      width={486}
      footer={[
        <Row>
          <Col xs={24} lg={12} />
          <Col xs={24} lg={12}>
          

            <div className="pt-4 border-t-2 border-dark-800 ">
                <div className="flex gap-2 justify-end items-center">
              
              <Button
                  text="No"
                  styles="secondary-outline"
                  onClick={closeModal}
                  disabled={isLoading}
                />
              
              <Button
                className="smd:hidden"
                text="Yes"
                // icon="add-white"
                type="submit"
                onClick={onYes}
                //loading={isLoading}
                />
                 </div>
            </div>
          </Col>
        </Row>
      ]}
    >
      {description && showDescriptionInFooter && (
        <div className="remove-webinar">
          <span className="delete-group">{description}</span>
        </div>
      )}
    </Modal>
  )
}

export default ConfirmModal
