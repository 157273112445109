import { useState, useEffect } from "react";

import { useAppDispatch } from "redux/hooks";
import { updateFax } from "redux/slice/faxSlice";
import { DownloadFile } from "components/Helper/DownloadFile";
import EditModal from "./EditModal";
import { Textarea } from "../forms";

import Table from "../Table/Table";
import { format } from "date-fns";

import download from "assets/icons/download.svg";
import trash from "assets/icons/trash.svg";
import edit from "assets/icons/edit.svg";

import { message } from "antd";
import Loader from "../loader/Loader";

interface EditSentFaxModalProps {
  visible: boolean;
  isLoading?: boolean;
  onCancelClick?: any;
  data: any;
  setSentFaxData?: any;
  setDeleteId?: any;
}

/* ============================== DELETE MODEL ============================== */

const EditSentFaxModal = (props: EditSentFaxModalProps) => {
  const {
    visible = false,
    isLoading = false,
    onCancelClick,
    data,
    setSentFaxData,
    setDeleteId,
  } = props;

  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [note, setNote] = useState<string>("");

  const [tableData, setTableData] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    const faxLogs: any[] = data?.fax_log?.map((entry: string) => {
      const matchResult = entry.match(
        /\[(\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2} UTC)\]\[([^\]]+@[\w.]+)\]\[([^\]]+)\]\[([^\]]+)]/
      );

      if (matchResult) {
        const [timestamp, email, ipAddress, action] = matchResult.slice(1);
        return { timestamp, email, ipAddress, action };
      }

      return null;
    });
    console.log(faxLogs);

    // Filter out null entries before setting the table data
    const filteredFaxLogs = faxLogs?.length ? faxLogs?.filter((entry) => entry !== null) : []
    setTableData(filteredFaxLogs);
    setLoading(false);
  }, [data]);

  // useEffect(() => {
  //     setLoading(true);
  //     if (Array.isArray(data)) {
  //         const faxLogs: any[] = data.map((entry: string) => {
  //             const matchResult = entry.match(/\[(.*?)\]/g);
  //             if (matchResult) {
  //                 const [timestamp, email, ipAddress, action] = matchResult.map((value) =>
  //                     value.replace(/\[|\]/g, "")
  //                 );
  //                 return { timestamp, email, ipAddress, action };
  //             }
  //             return null;
  //         });
  //         setTableData(faxLogs);
  //     } else {
  //         // Handle the case when data is not an array (e.g., setTableData([]) or any other appropriate action)
  //         setTableData([]);
  //     }
  //     setLoading(false);
  // }, [data]);

  const col = [
    {
      name: "Date",
      cell: (row: any) => {
        return (
          <div className="flex gap-2 items-center">
            {format(new Date(row.timestamp), "M-d-yyyy, hh:mm a")}
          </div>
        );
      },
      selector: (row: any) => row.timestamp,
      sortable: false,
    },
    {
      name: "Email",
      selector: (row: any) => row.email,
      sortable: false,
    },
    {
      name: "Action",
      selector: (row: any) => row.action,
      sortable: false,
    },
    {
      name: "Ip Address",
      selector: (row: any) => row.ipAddress,
      sortable: false,
    },
  ];

  const dispatch = useAppDispatch();

  const onSubmit = async () => {
    try {
      let payload = {
        id: data?.uuid,
        notes: note,
        read: "yes",
      };
      await dispatch(updateFax(payload)).unwrap();
      onCancelClick();
      setNote("");
      setIsEdit(false);
      message.success("Fax Edited successfully");
    } catch (error: any) {
      message.error("Fax can't be edited");
      console.log("Update fax err", error?.message);
    }
  };

  const onDownloadFax = async (id: string, path: string) => {
    const accessToken = localStorage.getItem("accessToken");
    fetch(path, {
      method: "GET",
      headers: {
        Accept: "application/pdf",
        "Content-Type": "application/pdf",
        responseType: "arraybuffer",
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.arrayBuffer();
        } else {
          throw new Error("Request failed with status " + response.status);
        }
      })
      .then((data) => {
        console.log("Request successful:", data);
        DownloadFile(data, id);
      })
      .catch((error) => {
        console.error("Error:", error);
        message.error("Sorry we couldn't download that document");
      });
  };

  return (
    <>
      <EditModal
        className={`!max-w-[780px] ${tableData?.length
            ? "!top-[calc(50%-290px)]"
            : "!top-[calc(50%-220px)]"
          }`}
        isLoading={isLoading}
        visible={visible}
        onCancelClick={() => {
          setNote("");
          setIsEdit(false);
          onCancelClick();
        }}
        onDoneClick={() => onSubmit()}
        doneText="Ok"
        cancelText="Cancel"
      >
        <div className="w-full pb-4">
          <div className="flex justify-between items-center pb-4">
            <label className="text-lg font-bold text-heading">
              Fax Details
            </label>
            <div className="flex gap-2">
              <div
                className="bg-primary-v10 p-2 border-primary-v8 rounded-lg cursor-pointer border"
                onClick={() => {
                  onDownloadFax(data?.uuid, data?.download_link);
                }}
              >
                <img src={download} alt="download" />
              </div>
              <div
                className="bg-primary-v10 p-2 border-primary-v8 rounded-lg cursor-pointer border"
                onClick={() => {
                  setDeleteId(data?.uuid);
                  setSentFaxData(null);
                }}
              >
                <img src={trash} alt="trash" />
              </div>
            </div>
          </div>
          <div className="p-4 bg-primary-v10 border border-primary-v8 rounded-lg">
            <div className="grid grid-cols-3 smd:grid-cols-1 gap-4">
              {/* <div className="flex flex-col items-start">
                                <label className="text-sm font-semibold text-heading">
                                    Sender Name
                                </label>
                                <label className="text-sm text-secondary">
                                    {data?.from_name || ""}
                                </label>
                            </div> */}
              <div className="flex flex-col items-start">
                <label className="text-sm font-semibold text-heading">
                  Sender Fax Number
                </label>
                <label className="text-sm text-secondary">
                  {data?.from_number || ""}
                </label>
              </div>
              <div className="flex flex-col items-start">
                <label className="text-sm font-semibold text-heading">
                  Date
                </label>
                <label className="text-sm text-secondary">
                  {data?.created_on || ""}
                </label>
              </div>
              {/* <div className="flex flex-col items-start">
                                <label className="text-sm font-semibold text-heading">
                                    Recipient Name
                                </label>
                                <label className="text-sm text-secondary">
                                    {data?.to_name || ""}
                                </label>
                            </div> */}
              <div className="flex flex-col items-start">
                <label className="text-sm font-semibold text-heading">
                  Recipient Fax Number
                </label>
                <label className="text-sm text-secondary">
                  {data?.to_number || ""}
                </label>
              </div>
              <div className="flex flex-col items-start">
                <label className="text-sm font-semibold text-heading">
                  No. of Pages
                </label>
                <label className="text-sm text-secondary">
                  {data?.pages || 0}
                </label>
              </div>
              {/* <div className="flex flex-col items-start">
                                <label className="text-sm font-semibold text-heading">
                                    Attachment
                                </label>
                                <label className="text-sm text-primary cursor-pointer">
                                    Preview Attachment
                                </label>
                            </div> */}
              <div className="flex flex-col items-start">
                <label className="text-sm font-semibold text-heading">
                  Status
                </label>
                <label
                  className={`${data?.status === "sending"
                      ? "text-primary"
                      : data?.status === "delivered"
                        ? "text-primary-green"
                        : data?.status === "deleted"
                          ? "text-layout"
                          : "text-[#FF455C]"
                    } text-sm`}
                >
                  {data?.status || ""}
                </label>
              </div>
            </div>
          </div>
          <div className="pt-4 px-2">
            <div className="flex justify-between items-center">
              <label className="text-base font-bold text-heading">Notes</label>
              <div
                className="cursor-pointer"
                onClick={() => {
                  !isEdit && setNote(data?.notes || "");
                  setIsEdit(!isEdit);
                }}
              >
                <img src={edit} alt="edit" width="18px" height="18px" />
              </div>
            </div>
            {isEdit ? (
              <div>
                <Textarea
                  name=""
                  placeholder="Enter a note"
                  value={note}
                  onChange={(e: any) => setNote(e.target.value)}
                />
              </div>
            ) : (
              <p className="pt-2 text-secondary text-xs">{data?.notes}</p>
            )}
          </div>
          {tableData?.length ? (
            <div className="pt-4 smd:hidden">
              <Table
                customHeight="!max-h-[250px] !h-[unset]"
                fixedHeaderScrollHeight="250px"
                columns={col}
                pagination={false}
                data={tableData}
                isLoading={loading}
              />
            </div>
          ) : null}
          <div className="pt-4 smd:block hidden">
            <div
              className={`hidden smd:block ${!tableData?.length && !isLoading
                  ? "hidden pb-0"
                  : "max-h-[calc(100vh-152px)]"
                } bg-[#F7FAFD] overflow-y-auto pb-[5px]`}
            >
              {isLoading ? (
                <Loader />
              ) : tableData?.length ? (
                <div className="">
                  {tableData.map((val: any, index: number) => {
                    return (
                      <div className="p-2" key={index}>
                        <div className="bg-white p-4 rounded-lg drop-shadow-sm">
                          <div className="grid grid-cols-5 items-center">
                            <div className="flex gap-3 items-center col-span-3">
                              <label className="text-sm">
                                {format(
                                  new Date(val.timestamp),
                                  "M-d-yyyy, hh:mm a"
                                ) || ""}
                              </label>
                            </div>
                            <div className="col-span-2 flex justify-end gap-4">
                              {val?.action || ""}
                            </div>
                          </div>
                          <div className="grid grid-cols-2 items-center pt-2">
                            <label className="text-sm">{val.email || ""}</label>
                            <label className="text-sm text-right">
                              {val.ipAddress || ""}
                            </label>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </EditModal>
    </>
  );
};

export default EditSentFaxModal;
