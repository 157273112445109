// PROJECT IMPORTS
import ConfirmationModal from "./ConfirmationModal";

// TYPES
interface MigrationModalProps {
    visible: boolean;
    isLoading?: boolean;
    onCancelClick?: any;
    onDoneClick?: any;
}

/* ============================== DELETE MODEL ============================== */

const MigrationModal = (props: MigrationModalProps) => {
    const {
        visible = false,
        isLoading = false,
        onCancelClick,
        onDoneClick,
    } = props;

    return (
        <>
            <ConfirmationModal
                isLoading={isLoading}
                visible={visible}
                onCancelClick={onCancelClick}
                onDoneClick={onDoneClick}
                doneText="Migrate"
                cancelText="Don't migrate"
                title="SecureFax"
                infoIcon={false}
                className="!max-w-[430px] !top-[calc(50%-150px)]"
            >
                <div>
                    <p className="text-sm text-[#8083A3]">
                        We see you currently have a SecureFax account. <span className="!text-primary cursor-pointer" onClick={onDoneClick}>Click here</span> to migrate
                        your faxes to the new portal.
                    </p>
                    <p className="pt-4 pb-3 text-sm text-black font-normal">
                        <b>Note: </b>Due to various security considerations, we cannot ensure the flawless migration of all faxes. Kindly ensure the download and preservation of any vital
                        faxes. Currently, access to the old portal is still available. You will be duly notified before the old portal undergoes official shutdown.
                    </p>
                </div>
            </ConfirmationModal>
        </>
    );
};

export default MigrationModal;
