// TYPES
interface CheckBoxProps {
    className?: string;
    label?: string;
    defaultChecked?: boolean;
    checked?: boolean;
    onChange?: any;
    onClick?: any;
    labelClassName?: string;
    readOnly?: boolean;
    value: any;
}

/* ============================== CHECK BOX ============================== */

const CheckBox = (props: CheckBoxProps) => {
    const {
        className,
        label,
        defaultChecked,
        checked,
        labelClassName,
        onChange,
        onClick,
        readOnly = false,
        value
    } = props;

    return (
        <div className="flex items-center">
            <input
                readOnly={readOnly}
                type="checkbox"
                className={`text-white accent-primary rounded mr-1.5 ${className}`}
                defaultChecked={defaultChecked}
                checked={checked}
                onChange={onChange}
                onClick={onClick}
                value={value}
            />
            <label className={`${labelClassName} text-sm mt-[2.5px]`}>{label}</label>
        </div>
    );
};

export default CheckBox;
