export const getFilenameFromContentDisposition = (headers: any) => {
    const contentDisposition = headers["content-disposition"];
    if (contentDisposition) {
        const filenameMatch = contentDisposition.match(/filename=["']?([^"']+)/);
        if (filenameMatch && filenameMatch[1]) {
            return filenameMatch[1];
        }
    }
    // If the header is not present or doesn't contain a filename, use a default name
    return "downloaded-file.pdf";
};

export const DownloadFile = (arrayBuffer: any, filename: string) => {
    const blob = new Blob([arrayBuffer], { type: "application/pdf" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${filename}`;
    a.style.display = "none";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
};
