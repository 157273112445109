import React from "react";
import Modal from "./Modal";
import { CheckBox, Input } from "../forms";

interface AddCoverPageModalProps {
    visible: boolean;
    onCancelClick: any;
}

const AddCoverPageModal = (props: AddCoverPageModalProps) => {
    const { visible = false, onCancelClick } = props;
    const [preview, setPreview] = React.useState();
    const [selectFile, setSelectFile] = React.useState<any>();

    React.useEffect(() => {
        if (!selectFile) {
            setPreview(undefined);
            return;
        }

        const objectUrl: any = URL.createObjectURL(selectFile);
        setPreview(objectUrl);

        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl);
    }, [selectFile]);

    return (
        <>
            <Modal
                visible={visible}
                doneText="Save"
                onCancelClick={() => {
                    setSelectFile(null);
                    onCancelClick();
                }}
            >
                <div className="w-full flex">
                    <div className="w-[60%] p-4">
                        <div className="pb-4">
                            <label className="text-lg font-bold text-heading">
                                Add Cover Page
                            </label>
                        </div>
                        <Input
                            label="Cover Page Name"
                            placeholder="Enter cover page name"
                            name="name"
                        />
                        <div className="py-2 flex items-center gap-4">
                            <label className="text-sm font-bold text-heading">
                                Upload Cover Page
                            </label>
                            <label className="bg-primary text-xs px-3 py-1.5 text-white rounded-md font-bold cursor-pointer">
                                <input
                                    type="file"
                                    className="hidden"
                                    accept="image/*"
                                    onChange={(e: any) => {
                                        setSelectFile(e.target.files[0]);
                                    }}
                                />
                                Choose file
                            </label>
                        </div>
                        <div className="">
                            <CheckBox
                                value={true}
                                label="Add to cover page list"
                                labelClassName="!text-xs"
                            />
                        </div>
                    </div>
                    <div className="w-[40%]">
                        <div className="px-4">
                            {preview ? (
                                <div className="h-[calc(100vh-255px)]">
                                    <img src={preview} alt="preview" height="100%" />
                                </div>
                            ) : (
                                <div className="bg-[#f7fafd] h-[calc(100vh-255px)] flex items-center justify-center">
                                    <label className="text-xl font-bold text-[#E0E2EB]">
                                        No preview available
                                    </label>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default AddCoverPageModal;
