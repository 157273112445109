import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faBackwardStep,
    faChevronLeft,
    faChevronRight,
    faForwardStep,
} from "@fortawesome/free-solid-svg-icons";

// THIRD-PARTY IMPORT
import ReactPaginate from "react-paginate";
import React from "react";

// TYPES
interface PaginationProps {
    className?: string;
    page?: number;
    totalCount?: number;
    limit?: number;
    isAdvance?: boolean;
    onPageClick?: any;
    classNameMain?: string;
    count: number;
    onLimitChange?: any;
    firstPageClick?: any;
    lastPageClick?: any;
}
const options = [
    { value: "10", label: 10 },
    { value: "20", label: 20 },
    { value: "30", label: 30 },
    { value: "40", label: 40 },
    { value: "50", label: 50 },
    { value: "100", label: 100 },
];
/* ============================== PAGINATION ============================== */

const Pagination = (props: PaginationProps) => {
    const {
        className,
        page = 1,
        totalCount = 0,
        limit = 10,
        isAdvance,
        onPageClick,
        classNameMain,
        onLimitChange,
        firstPageClick,
        lastPageClick,
        count = 0,
    } = props;
    const totalPage = Math.ceil(totalCount / limit);
    const end = isAdvance ? totalCount : Math.min(page * limit, totalCount);

    const handlePageClick = (event: any) => {
        onPageClick(event.selected + 1);
    };

    const disabledOptions = (val: any) => {
        // eslint-disable-next-line eqeqeq
        if ((end == totalCount && parseInt(val?.value) > limit) || isAdvance)
            return true;
        return false;
    };

    return (
        <div
            className={`${totalCount === 0 && "!justify-center"
                } w-full bg-white py-2 flex justify-between items-center px-6 smd:px-2 rounded-b-lg border-[#EDEDED] border-t`}
        >
            <div className={`${classNameMain} flex items-center`}>
                {totalCount > 0 ? (
                    <div className="pr-2 mt-1">
                        <div
                            className={`cursor-pointer hidden smd:block ${page === 1 && "opacity-50 pointer-events-none"
                                }`}
                            onClick={firstPageClick}
                        >
                            <FontAwesomeIcon
                                className="cursor-pointer px-2 py-1.5 border-[#ECEEF5] border rounded-md"
                                color="#474747"
                                icon={faBackwardStep}
                                size="sm"
                            />
                        </div>
                    </div>
                ) : null}
                <ReactPaginate
                    containerClassName={`flex gap-1 items-center ${className}`}
                    pageClassName={`px-2 py-1 rounded-md text-txt-primary font-normal text-xs border-[#ECEEF5] border smd:hidden`}
                    activeClassName="border-primary border !text-primary text-xs smd:!block"
                    breakLabel=" . . "
                    forcePage={page - 1}
                    breakClassName="text-txt-primary font-normal px-2 py-1 smd:hidden"
                    nextLabel={
                        <FontAwesomeIcon
                            className="cursor-pointer px-2 py-1.5 border-[#ECEEF5] border rounded-md"
                            color="#474747"
                            icon={faChevronRight}
                        />
                    }
                    nextClassName={`${page === totalPage ? "text-txt-secondary" : "text-txt-primary"
                        } smd:pl-1 pl-2 text-xs`}
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={4}
                    pageCount={isAdvance ? 1 : totalPage}
                    previousLabel={
                        <FontAwesomeIcon
                            className="cursor-pointer px-2 py-1.5 border-[#ECEEF5] border rounded-md"
                            color="#474747"
                            icon={faChevronLeft}
                        />
                    }
                    previousClassName={`${page === 1 ? "text-txt-secondary" : "text-txt-primary"
                        } smd:pr-1 pr-2 text-xs`}
                    renderOnZeroPageCount={null}
                />
                {totalCount > 0 ? (
                    <div className="pl-2 mt-1">
                        <div
                            className={`cursor-pointer hidden smd:block ${page === totalPage && "opacity-50 pointer-events-none"
                                }`}
                            onClick={lastPageClick}
                        >
                            <FontAwesomeIcon
                                className="cursor-pointer px-2 py-1.5 border-[#ECEEF5] border rounded-md"
                                color="#474747"
                                icon={faForwardStep}
                                size="sm"
                            />
                        </div>
                    </div>
                ) : null}
            </div>
            {totalCount > 0 ? (
                <div className="flex items-center gap-4">
                    <div className="border-2 border-dark-700 rounded-md">
                        <select
                            value={limit}
                            onChange={(e: any) => onLimitChange(e.target.value)}
                            className={`block p-1 3xl:text-sm text-xs w-full focus:outline-none 3xl:border-2 border rounded-md border-transparent rounded-e-md font-normal 3xl:border-r-8 border-r-2 text-txt-primary`}
                        >
                            {options?.map((val: any, index: number) => {
                                return (
                                    <React.Fragment key={index}>
                                        <option disabled={disabledOptions(val)} value={val?.value}>
                                            {val?.label}
                                        </option>
                                    </React.Fragment>
                                );
                            })}
                        </select>
                    </div>
                    <div className="rounded-lg border border-[#ECEEF5] px-2 py-1">
                        <label className="text-xs mr-1 font-bold">{count || 0}</label>/
                        <label className="text-xs ml-1">{totalCount || 0}</label>
                    </div>
                </div>
            ) : null}
        </div>
    );
};

export default Pagination;
