/* eslint-disable react/jsx-pascal-case */
import MainLayout from "./components/layouts/MainLayout";

export default function App() {
  return (
    <>
      <MainLayout/>
    </>
  );
}
