// PROJECT IMPORTS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "../forms";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";

// TYPES
interface EditModalProps {
    doneClassName?: string;
    cancelClassName?: string;
    doneStyle?: string;
    cancelStyle?: string;
    title?: string;
    content?: string;
    doneText?: string;
    cancelText?: string;
    isLoading?: boolean;
    children?: React.ReactNode;
    visible: boolean;
    onCancelClick?: any;
    onDoneClick?: any;
    className?: string;
    cancel?: boolean;
    infoIcon?: boolean;
    form?: string;
    type?: "submit" | "button" | "reset" | undefined;
}

/* ============================== DELETE MODEL ============================== */

const EditModal = (props: EditModalProps) => {
    const {
        doneClassName = "",
        cancelClassName = "",
        doneStyle = "primary",
        cancelStyle = "dark-outline",
        title,
        content,
        doneText = "Yes",
        cancelText = "No",
        isLoading = false,
        children,
        visible = false,
        onCancelClick,
        onDoneClick,
        className = "",
        cancel = true,
        infoIcon = true,
        form,
        type = "button",
    } = props;

    return (
        <>
            <div
                id="popup-modal"
                tabIndex={-1}
                className={`fixed top-0 left-0 right-0 z-[60] smd:z-[1] smd:pt-[55px] px-4 smd:px-0 overflow-x-hidden overflow-y-auto md:inset-0 h-[100%] max-h-full bg-[#18508D] bg-opacity-60 smd:bg-white smd:bg-opacity-100 ${!visible && "hidden"
                    }`}
                onClick={onCancelClick}
            >
                <div className="w-full h-full relative flex justify-center">
                    <div
                        className={`absolute smd:static max-w-[550px] top-[calc(50%-88px)] w-full max-h-full ${className}`}
                    >
                        <div
                            className="bg-white rounded-lg shadow smd:shadow-none smd:rounded-none smd:h-full"
                            onClick={(e: any) => e.stopPropagation()}
                        >
                            <div className="pt-7 flex flex-col items-start w-full smd:h-full justify-between">
                                <div className="px-7 w-full">
                                    {title ? (
                                        <div className="flex gap-2 items-center pb-2">
                                            {infoIcon ? (
                                                <FontAwesomeIcon
                                                    color="#FF455C"
                                                    icon={faCircleInfo}
                                                    size="lg"
                                                />
                                            ) : null}
                                            <p className="font-bold text-sm">{title}</p>
                                        </div>
                                    ) : null}
                                    {children ? (
                                        children
                                    ) : (
                                        <p className="mb-10 text-xs font-normal text-txt-primary max-w-[70%]">
                                            {content}
                                        </p>
                                    )}
                                </div>
                                <div className="pb-4 w-full flex items-center justify-end pr-6 smd:sticky smd:bottom-0 smd:bg-white smd:z-10">
                                    <div className="flex gap-4">
                                        {cancel ? (
                                            <Button
                                                className={`${cancelClassName} px-4 py-1.5 !border-none`}
                                                text={cancelText}
                                                styles={cancelStyle}
                                                onClick={onCancelClick}
                                            />
                                        ) : null}
                                        <Button
                                            type={type}
                                            form={form}
                                            isLoading={isLoading}
                                            disabled={isLoading}
                                            className={`${doneClassName} px-4 py-2`}
                                            text={doneText}
                                            styles={doneStyle}
                                            onClick={onDoneClick}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EditModal;
