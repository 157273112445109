import { useMemo } from "react";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  faxDownload,
  faxListDelete,
  faxListGet,
  faxResend,
  faxSend,
  faxUpdate,
  migrationUser,
  userInfoGet,
  userNumberGet,
  contactListGet,
  contactCreate,
  contactEdit,
  faxSendingstatus,
} from "redux/services/faxService";
import { useAppSelector } from "redux/hooks";
import { RootState } from "redux/store";
import { format, isToday, isYesterday } from "date-fns";

interface InitialState {
  inputFaxList: any;
  inputContactList: any;
  sentFaxList: any;
  sendingFaxList: any;
  userDetails: any;
  sentCount: number;
  inCount: number;
  numberDetails: any;
}

/* ============================== FAX SLICE ============================== */

const initialState: InitialState = {
  inputFaxList: {},
  inputContactList: {},
  sentFaxList: {},
  sendingFaxList: {},
  userDetails: {},
  numberDetails: {},
  sentCount: 0,
  inCount: 0,
};

export const getFaxList = createAsyncThunk(
  "fax-list/list",
  async (params: any) => {
    return await faxListGet(params);
  }
);

export const getContactList = createAsyncThunk(
  "contact-list/list",
  async (params: any) => {
    return await contactListGet(params);
  }
);


export const  createContact = createAsyncThunk(
  "fax-list/send",
  async (payload: any) => {
    return await contactCreate(payload);
  }
);

export const  editContact = createAsyncThunk(
  "fax-list/send",
  async (payload: any) => {
    return await contactEdit(payload);
  }
);

export const getSentFaxList = createAsyncThunk(
  "fax-sent-list/list",
  async (params: any) => {
    return await faxListGet(params);
  }
);

export const getSendingfaxstatus = createAsyncThunk(
  "fax-status-list/list",
  async (params: any) => {
    return await faxSendingstatus(params);
  }
);

export const getSendingFaxList = createAsyncThunk(
  "fax-sending-list/list",
  async (params: any) => {
    return await faxListGet(params);
  }
);

export const downloadFax = createAsyncThunk("fax/download", async (id: any) => {
  return await faxDownload(id);
});

export const resedFax = createAsyncThunk("fax/resend", async (id: any) => {
  return await faxResend(id);
});

export const deleteFax = createAsyncThunk(
  "fax-list/delete",
  async (payload: any) => {
    return await faxListDelete(payload);
  }
);

export const sendFax = createAsyncThunk(
  "fax-list/send",
  async (payload: any) => {
    return await faxSend(payload);
  }
);

export const updateFax = createAsyncThunk(
  "fax-list/update",
  async (payload: any) => {
    const { id, ...rest } = payload;
    return await faxUpdate(id, rest);
  }
);

export const getUserInfo = createAsyncThunk(
  "user-info/get",
  async (username: string) => {
    return await userInfoGet(username);
  }
);

export const getUserNumber = createAsyncThunk(
  "user-number/get",
  async (number: string) => {
    return await userNumberGet(number);
  }
);

export const userMigration = createAsyncThunk(
  "user/migration",
  async (payload: any) => {
    return await migrationUser(payload);
  }
);

const prepareData = (payload: any) => {
  const data = payload;
  let ndata: any = [];

  if (data.faxes !== null) {
    // eslint-disable-next-line array-callback-return
    ndata = data.faxes.map((x: any) => {
      return {
        ...x,
        created_on: isToday(new Date(x.created_on))
          ? "Today, " + format(new Date(x.created_on), "hh:mm a")
          : isYesterday(new Date(x.created_on))
            ? "Yesterday, " + format(new Date(x.created_on), "hh:mm a")
            : format(new Date(x.created_on), "M-d-yyyy, hh:mm a"),
      };
    });
  }

  return {
    current_page: data?.current_page,
    per_page: data?.per_page,
    total_faxes: data?.total_faxes,
    total_pages: data?.total_pages,
    faxes: [...ndata],
  };
};

const faxSlice = createSlice({
  name: "faxList",
  initialState,
  reducers: {
    clearSlice: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(
      getFaxList.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.inputFaxList = prepareData(action.payload);
      }
    );

    builder.addCase(
      getContactList.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.inputContactList = action.payload;
      }
    );

    builder.addCase(
      getSentFaxList.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.sentFaxList = prepareData(action.payload);
      }
    );

    builder.addCase(
      getSendingFaxList.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.sendingFaxList = prepareData(action.payload);
      }
    );

    builder.addCase(
      getUserInfo.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.userDetails = action.payload;
      }
    );

    builder.addCase(
      getUserNumber.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.numberDetails = action.payload;
      }
    );
  },
});

export default faxSlice.reducer;
export const { clearSlice } = faxSlice.actions;

export const selectInputFaxDetails = (state: RootState) =>
  state.faxList.inputFaxList;
export const useInputListDetails = () => {
  const inputFaxList = useAppSelector(selectInputFaxDetails);
  return useMemo(() => inputFaxList, [inputFaxList]);
};

export const selectInputContactDetails = (state: RootState) =>
  state.faxList.inputContactList;
export const useInputContactDetails = () => {
  const inputContactList = useAppSelector(selectInputContactDetails);
  return useMemo(() => inputContactList, [inputContactList]);
};

export const selectSentFaxDetails = (state: RootState) =>
  state.faxList.sentFaxList;
export const useSentListDetails = () => {
  const sentFaxList = useAppSelector(selectSentFaxDetails);
  return useMemo(() => sentFaxList, [sentFaxList]);
};

export const selectSendingFaxDetails = (state: RootState) =>
  state.faxList.sendingFaxList;
export const useSendingListDetails = () => {
  const sendingFaxList = useAppSelector(selectSendingFaxDetails);
  return useMemo(() => sendingFaxList, [sendingFaxList]);
};

export const selectUserDetails = (state: RootState) =>
  state.faxList.userDetails;
export const useUserDetails = () => {
  const userDetails = useAppSelector(selectUserDetails);
  return useMemo(() => userDetails, [userDetails]);
};

export const selectUserNumberDetails = (state: RootState) =>
  state.faxList.numberDetails;
export const useNumberDetails = () => {
  const numberDetails = useAppSelector(selectUserNumberDetails);
  return useMemo(() => numberDetails, [numberDetails]);
};